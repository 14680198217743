import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  padding: ${ui.rem("md")};
`;

export const container = css`
  ${ui.row("md")};
  ${ui.flexCenter()};
`;

export const firstColumn = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  align-items: flex-start;
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 2px;

  ${ui.column(0)};

  ${getMediaMin("md")(`
    font-size: 64px;
  `)}
`;

export const titleColor = (
  color: "#94c6bb" | "#DA7059" | "#F5CC42" | "#808080"
) => {
  return css`
    color: ${color};
  `;
};

export const titleSmall = css`
  font-weight: 600;
  font-size: 32px;
`;

export const subtitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #da7059;
  margin-bottom: ${ui.rem("sm")};

  ${getMediaMin("md")(`
    font-size: 25px;
  `)}
`;

export const button = css`
  cursor: pointer;
  background: #7c45be;
  border-radius: 39px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  padding: ${ui.rem(15)} ${ui.rem(30)};

  :hover {
    background: #703cae;
  }

  :active {
    background: #64359e;
  }
`;

export const imgLeft = css``;

export const imgRight = css`
  ${getMediaMax("lg")(`
    display: none;
  `)}
`;
