import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export type LoadType = "open" | "closing" | "close" | "close-full";

export const loadingTop = (loaded: LoadType) => {
  const getVisible = () => {
    if (loaded === "open") {
      return `
      opacity: 1;
      `;
    } else if (loaded === "closing") {
      return `
      opacity: 1;
      `;
    } else if (loaded === "close") {
      return `
        opacity: 0;
      `;
    } else {
      return `
        display: none;
      `;
    }
  };

  return css`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #f7db38;
    z-index: 4;
    transition: opacity 1000ms;
    ${ui.flexCenter()};
    ${getVisible()}
  `;
};

export const logoContainer = css`
  width: 100%;
  max-width: ${ui.rem(120)};

  * {
    fill: #ffffff !important;
  }
`;
