import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

export const section = css`
  position: relative;
  background: #fff6a0;
  padding-top: ${ui.rem("xl")};
  overflow: hidden;
`;

export const decorationEqual = css`
  position: absolute;
  top: calc(${ui.rem("xl")} * 2);
  left: calc(${ui.rem("xl")} * -1);
`;

export const decorationLine = css`
  position: absolute;
  bottom: calc(${ui.rem("xl")} * 4);
  right: calc(${ui.rem("xl")} * -1);

  ${getMediaMin("md")(`
  right: calc(${ui.rem("xl")} * -1 - ${ui.rem(20)});
`)}
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css`
  max-width: ${ui.rem(900)};
`;

export const formContainer = css`
  height: 100%;
  position: relative;
  ${ui.column("md")};
  margin-top: ${ui.rem(150)};
  margin-bottom: ${ui.rem(100)};
  max-width: 500px;
  width: 100%;
`;

export const imgKid1 = css`
  position: absolute;
  top: calc(${ui.rem(110)} * -1);
  right: calc(${ui.rem(50)} * -1);

  ${getMediaMin("md")(`
    top: calc(${ui.rem(145)} * -1);
  `)}
`;

export const imgKid2 = css`
  position: absolute;
  bottom: calc(${ui.rem("xl")} * -1);
  left: calc(${ui.rem(200)} * -1);

  ${getMediaMax("md")(`
    display: none;
  `)}
`;

export const imgLion = css`
  position: absolute;
  bottom: calc(${ui.rem("xl")} * -1);
  right: calc(${ui.rem(200)} * -1);

  ${getMediaMax("md")(`
    display: none;
  `)}
`;
