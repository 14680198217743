import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css``;

export const videoContainer = css`
  background-image: URL("./files/voluntaries-1.png");
  padding: ${ui.rem("md")};
  padding-bottom: ${ui.rem("xl")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const video = css`
  max-width: ${ui.rem(1000)};
  width: 100%;

  :focus-visible {
    outline: none;
  }
`;

export const imgBaby = css`
  position: absolute;
  left: calc(${ui.rem("md")} * -1);
  width: ${ui.rem(150)};

  ${getMediaMin("lg")(`
    width: ${ui.rem(250)};
    bottom:  ${ui.rem(-50)};
    left: ${ui.rem(-120)};
  `)}
`;
