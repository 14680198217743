/** @jsxImportSource @emotion/react */
import { Scaffold } from "../../components";
import {
  HomeBannerSection,
  HomeDoYouKnownSection,
  HomeFinalSection,
  HomeGoodHomeSection,
  HomeHealthySection,
  HomeHowDoWeDoItSection,
  HomeHumanFormationSection,
  HomeIntegrationSection,
  HomePresentationSection,
  HomePsychologySection,
} from "./sections";

import * as styles from "./styles";

export const HomePage = () => {
  return (
    <Scaffold css={styles.page}>
      <HomeBannerSection />
      <HomePresentationSection />
      <HomeDoYouKnownSection />
      <HomeHowDoWeDoItSection />
      <HomeGoodHomeSection />
      <HomeHealthySection />
      <HomePsychologySection />
      <HomeHumanFormationSection />
      <HomeIntegrationSection />
      <HomeFinalSection />
    </Scaffold>
  );
};
