/** @jsxImportSource @emotion/react */
import { useLayoutEffect, useState, memo } from "react";

import * as styles from "./styles";

export interface AnimationLeafProps {
  css?: any;
  className?: string;
  delay?: number;
  version?: number;
  theme?: "orange" | "green";
}

export const AnimationLeaf = memo(
  ({
    className = "",
    delay,
    version = 1,
    theme = "green",
  }: AnimationLeafProps) => {
    const [id] = useState(`${Math.random() * 20000}`);
    const [show, setShow] = useState(false);

    useLayoutEffect(() => {
      // @ts-ignore
      const instance = bodymovin.loadAnimation({
        container: document.getElementById(`animation-${id}`),
        path: `./files/animation-leaf-${version}${
          theme === "green" ? "" : "-o"
        }.json`,
        renderer: "svg",
        loop: true,
        autoplay: false,
        name: "Animation leafs",
      });

      if (delay) {
        instance.play();

        setTimeout(function () {
          setShow(true);
        }, delay);
      } else {
        instance.play();
      }

      // Return clean up function here
      return () => instance.destroy();
    });

    if (!show) return <></>;

    return (
      <div
        id={`animation-${id}`}
        className={`${className}`}
        css={[styles.decoration]}
      ></div>
    );
  }
);
