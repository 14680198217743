/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import {
  Appbar,
  CallToAction,
  Conferences,
  Contact,
  Footer,
  Presentation,
  Sponsors,
  Title,
  Workshops,
} from "./components";
import { Panel } from "./components/Panel";
import * as styles from "./styles";

export const Congress2023 = () => {
  const homeRef = useRef(null);
  const panelRef = useRef(null);
  const programRef = useRef(null);
  const sponsorRef = useRef(null);

  return (
    <div css={styles.page}>
      <div ref={homeRef} css={styles.header}>
        <Appbar
          homeRef={homeRef}
          panelRef={panelRef}
          programRef={programRef}
          sponsorRef={sponsorRef}
        />
        <Presentation />
      </div>
      <div ref={panelRef}>
        <Title title="Ponentes invitados" withDecoration />
        <Panel />
      </div>
      <div ref={programRef}>
        <Title title="Programa" subtitle="conferencias" />
        <Conferences />
      </div>
      <div>
        <Title title="Programa" subtitle="talleres" />
        <Workshops />
      </div>
      <CallToAction />
      <div ref={sponsorRef}>
        <Sponsors />
      </div>
      <Contact />
      <Footer />
    </div>
  );
};
