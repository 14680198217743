import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../utils";

export type ParagraphThemeType = "light" | "dark";
export type ParagraphDirectionType = "left" | "center" | "right";

export const texts = ({
  direction,
  directionLg,
  width,
  type,
}: {
  direction: ParagraphDirectionType;
  directionLg: ParagraphDirectionType;
  width: string | number;
  type: "half" | "full";
}) => {
  const getDirection = (direction: ParagraphDirectionType) => {
    if (direction === "center") {
      return "center";
    } else if (direction === "right") {
      return "flex-end";
    } else {
      return "flex-start";
    }
  };

  return css`
    ${ui.column("sm")};
    ${type === "half"
      ? `max-width: ${typeof width === "string" ? width : ui.rem(width)};`
      : `max-width: ${typeof width === "string" ? width : ui.rem(800)};`}
    align-items: ${getDirection(direction)};

    @media (min-width: ${ui.breakpoints.lg}px) {
      align-items: ${getDirection(directionLg)};
    }

    ${getMediaMin("md")(`
      ${ui.column("md")};
    `)}
  `;
};

export const title = ({
  direction,
  directionLg,
  theme,
}: {
  direction: ParagraphDirectionType;
  directionLg: ParagraphDirectionType;
  theme: ParagraphThemeType;
}) => {
  const getDirection = (direction: ParagraphDirectionType) => {
    if (direction === "center") {
      return "center";
    } else if (direction === "right") {
      return "right";
    } else {
      return "left";
    }
  };

  return css`
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: ${ui.rem(38)};
    line-height: ${ui.rem(44)};
    color: #ffffff;
    text-align: ${getDirection(direction)};
    color: ${theme === "light" ? "#ffffff" : "#797268"};

    @media (min-width: ${ui.breakpoints.md}px) {
      font-size: ${ui.rem(58)};
      line-height: ${ui.rem(64)};
    }

    @media (min-width: ${ui.breakpoints.lg}px) {
      text-align: ${getDirection(directionLg)};
    }
  `;
};

export const text = ({
  direction,
  directionLg,
  theme,
}: {
  direction: ParagraphDirectionType;
  directionLg: ParagraphDirectionType;
  theme: ParagraphThemeType;
}) => {
  const getDirection = (direction: ParagraphDirectionType) => {
    if (direction === "center") {
      return "center";
    } else if (direction === "right") {
      return "right";
    } else {
      return "left";
    }
  };

  return css`
    *,
    & {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: ${ui.rem(18)};
      line-height: ${ui.rem(30)};
      text-align: ${getDirection(direction)};
      color: ${theme === "light" ? "#ffffff" : "#797268"};

      @media (min-width: ${ui.breakpoints.md}px) {
        font-size: ${ui.rem(24)};
        line-height: ${ui.rem(35)};
      }

      @media (min-width: ${ui.breakpoints.lg}px) {
        text-align: ${getDirection(directionLg)};
      }

      strong {
        font-weight: 500;
      }
    }
  `;
};
