/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  Button,
  DecorationSquare,
  DecorationW,
  Image,
  Input,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const ContactFormSection = ({
  setForm,
  form,
  sendInformation,
  showAlert,
}: any) => {
  const onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((form: any) => {
      return {
        ...form,
        [name]: value,
      };
    });
  };

  const inputs = [
    {
      name: "name",
      label: "Nombre",
      placeholder: "Escribir nombre",
      value: form["name"],
    },
    {
      name: "email",
      label: "Correo",
      placeholder: "Escribir correo",
      value: form["email"],
    },
    {
      name: "phone",
      label: "Teléfono",
      placeholder: "Escribir teléfono",
      value: form["phone"],
    },
    {
      name: "message",
      label: "Mensaje",
      placeholder: "Escribir mensaje",
      value: form["message"],
      textArea: true,
    },
  ];

  return (
    <section css={styles.section}>
      <DecorationSquare css={styles.decorationSquare} />
      <DecorationW css={styles.decorationW} />

      <ComponentContainer css={styles.container}>
        <Image
          css={styles.imgKids}
          src="./files/contact-5.png"
          alt="Niños escribiendo en computadora"
        />
        <div css={styles.form}>
          {inputs.map((input, index) => {
            return <Input key={index} onChange={onChange} {...input} />;
          })}

          <Button type="md" css={styles.button} onClick={sendInformation}>
            Contáctanos
          </Button>
        </div>
      </ComponentContainer>

      <ComponentContainer css={styles.containerText}>
        {showAlert && (
          <p css={styles.textAlert(showAlert)}>
            Muchas gracias por querer ser parte de nuestra historia, estaremos
            en contacto contigo muy pronto.
          </p>
        )}
      </ComponentContainer>

      <ComponentContainer css={styles.containerText}>
        <a href="tel:+529999486663">
          <Paragraph
            text="Si quieres ser parte del equipo de voluntarios de Fundación AMAR, contáctanos al teléfono +52 999 948 6663.
          "
            direction="center"
            directionLg="center"
            type="full"
          />
        </a>
      </ComponentContainer>
    </section>
  );
};
