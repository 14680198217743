import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const section = css`
  position: relative;
  ${ui.column("lg")};
  margin-bottom: -${ui.rem(100)};
`;

export const beforeContainer = css`
  position: relative;
  background-color: #efd95f;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  margin-top: ${ui.rem("lg")};
  margin-bottom: ${ui.rem("lg")};
`;

export const text = css`
  max-width: ${ui.rem(900)};
`;

export const imgPeople = css`
  max-width: ${ui.rem(800)};
  ${ui.flexCenter()};
  margin: auto;

  img {
    width: 100%;
  }
`;
