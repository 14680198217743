/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { useState } from "react";
import {
  Button,
  DecorationCloud,
  DecorationSpiral,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const DonationKidsSection = () => {
  return (
    <section css={styles.section}>
      <DecorationSpiral css={styles.decorationSpiral} />
      <DecorationCloud css={styles.decorationCloud} />

      <div css={styles.containerTop}>
        <Image
          css={styles.imgBg}
          src="./files/faq-2.png"
          alt="Preguntas frecuentes título"
        />
        <ComponentContainer css={styles.container}>
          <QuestionsModule />
        </ComponentContainer>
      </div>
      <ComponentContainer css={styles.containerButton}>
        <Button to="/donaciones">Dona aquí</Button>
        <Paragraph
          css={styles.textNote}
          text="Considera ser un padrino de nuestra fundación si quieres ayudarnos a brindar esperanza a pequeños que vienen de ambientes de violencia y abandono."
          direction="center"
          directionLg="center"
          type="full"
        />
      </ComponentContainer>
    </section>
  );
};

const QuestionsModule = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const questions: any[] = [
    {
      q: "1. ¿Quiénes somos?",
      a: "Somos una casa de transición a la familia que brinda atención integral a niños y niñas víctimas de la violencia, el abandono y maltratados en la primera infancia, que carecen de figuras parentales.",
    },
    {
      q: "2. ¿Qué hacemos?",
      a: "Trabajamos con los niños a fin de rehabilitarlos emocional y físicamente, para que puedan relacionarse sin violencia, recuperar su nivel escolar, para que puedan vivir en una familia amorosa que los proteja e impulse a convertirse en personas productivas y con valores que fortalezcan el tejido social.",
    },
    {
      q: "3. ¿Cómo puedo adoptar?",
      a: "Nosotros, no somos la institución que se encarga de  esta área, tendría que pedir informes en la PRODENNAY  ( procuraduría de defensa  de niños y niñas del estado de Yucatán).",
    },
    {
      q: "4. ¿Cómo puedo apoyar?",
      a: "En fundación Amar tenemos muchos programas de apoyo, voluntariado, practicas profesionales, servicio social, cursos extra escolares, artes y deportes, contáctanos para mayores informes al teléfono 9999697394.",
    },
    {
      q: "5. ¿Quiero ser padrino?",
      a: "Tenemos programas de apadrinamiento que cubren las necesidades de los niños para ayudarnos a garantizar su bienestar.",
    },
    {
      q: "6. Si dono, ¿En qué se va mi dinero?",
      a: "Lo primordial para nosotros es garantizar una sana nutrición, y en su Educación, también será utilizado en los programas que manejamos; vivienda digna, salud psicológica, salud física.",
    },
    {
      q: "7. ¿Cuánto tiempo tarda un niño en ser adoptado?",
      a: "La adopción es un proceso que tarda de 6 meses a 2 años, pero es importante dejar en claro que cada niño es un caso diferente con un ritmo diferente de adaptación en todo proceso, y que todo los tiempos son a beneficio del menor y la restitución de sus derechos.",
    },
    {
      q: "8. ¿Qué puedo donar?",
      a: `
      Gracias al apoyo de la sociedad, que con sus donativos nos permiten solventar todas las necesidades de los niños,  garantizar su bienestar y atención adecuada.
las forma de Donativos que puedes aportar son:
<br/>a) Donaciones en Efectivo, mediante los programas existentes a nuestra cuentas
<br/>b) Donaciones en Especie ( alimentos, productos de limpieza, papelería, puedes enviarlo mediante aplicaciones y tiendas virtuales con envió a nuestra dirección)
<br/>c) Donaciones en Especie de primera o segunda mano(recibimos donación de ropa, muebles, juguetes, útiles escolares, siempre y cuando se encuentren en buen estado y aún tengan vida útil)
<br/>d) Donación de Servicios profesionales y tiempo (especialistas en todas las áreas)
      `,
    },
    {
      q: "9. ¿Puedo ser voluntario?",
      a: "Claro, que sí, siempre nos es útil unas manos extras, apoyarnos en eventos específicos, para dar cursos los fines de semana, para el área administrativa y operativa, comunícate con nosotros para más informes.",
    },
  ];

  const onSelectQuestion = (question: any) => {
    setSelected(question);
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (!questions) return <></>;
  if (questions.length === 0) return <></>;

  return (
    <div css={styles.questionContainer}>
      <button css={styles.questionButton} onClick={onOpen}>
        <span>{selected?.q || "Seleccionar pregunta frecuente"}</span>
        <i className="fas fa-chevron-down"></i>
      </button>
      <ul css={styles.questionList(open)}>
        {questions.map((question, index) => {
          return (
            <li
              key={index}
              css={styles.questionListItem}
              onClick={() => onSelectQuestion(question)}
            >
              {question.q}
            </li>
          );
        })}
      </ul>
      {selected && (
        <div
          css={styles.questionAnswer}
          dangerouslySetInnerHTML={{ __html: selected?.a }}
        ></div>
      )}
    </div>
  );
};
