/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { LogoComponent } from "../../components";

import * as styles from "./styles";

export const Loading = ({ children }: any) => {
  const [loaded, setLoaded] = useState<styles.LoadType>("open");

  const onLoad = (loading: any, loaded: any) => {
    if (document.readyState === "complete") {
      return loaded();
    }
    loading();
    if (window.addEventListener) {
      window.addEventListener("load", loaded, false);
    }
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", `overflow:hidden;`);

    onLoad(
      function () {},
      function () {
        const body = document.getElementsByTagName("body")[0];
        body.setAttribute("style", ``);
        setLoaded("closing");

        setTimeout(() => {
          setLoaded("close");
        }, 200);

        setTimeout(() => {
          setLoaded("close-full");
        }, 1300);
      }
    );
  }, []);

  return (
    <div css={styles.loadingTop(loaded)}>
      <div css={styles.logoContainer}>
        <LogoComponent />
      </div>
    </div>
  );
};
