/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Presentation = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <div css={styles.firstColumn}>
          <Image
            css={styles.imgLeft}
            src="./congress-2023/presentation-1.png"
            alt="Fundación amar mapa"
          />
          <div>
            <h1 css={styles.title}>
              <span css={styles.titleColor("#94c6bb")}>
                TRAUMA<span css={styles.titleSmall}>COMPLEJO</span>
              </span>
              <span css={styles.titleColor("#DA7059")}>RESILIENCIA</span>
              <span css={styles.titleColor("#F5CC42")}>ADOPCIÓN</span>
              <span css={styles.titleColor("#808080")}>APEGO</span>
            </h1>
            <p css={styles.subtitle}>El Gran Museo del Mundo Maya</p>
            <p css={styles.subtitle}>Mérida, Yucatán.</p>
          </div>
          <a
            href="http://amar2023.registrodeeventos.com/"
            target="_blank"
            rel="noreferrer"
            css={styles.button}
          >
            Registrarme
          </a>
        </div>
        <div>
          <Image
            css={styles.imgRight}
            src="./congress-2023/presentation-2.png"
            alt="Fundación amar mapa"
          />
        </div>
      </ComponentContainer>
    </section>
  );
};
