/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Footer = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <a
          href="https://www.fundacionamar.org/"
          target="_blank"
          rel="noreferrer"
          css={styles.texts()}
        >
          <Image
            css={styles.imgLogo()}
            src="./congress-2023/footer-1.svg"
            alt="Fundación amar contacto"
          />
          <div css={styles.texts()}>
            <p css={styles.text1()}>
              Haz click y conoce más sobre nuestra labor
            </p>
            <p css={styles.text2()}>Fundación AMAR del Sureste I.A.P.</p>
            <p css={styles.text3()}>C. 19 129, México, 97125 Mérida, Yuc.</p>
          </div>
        </a>
      </ComponentContainer>
      <Image
        css={styles.imgDecoration()}
        src="./congress-2023/footer-2.svg"
        alt="Fundación amar contacto"
      />
    </section>
  );
};

{
  /* <Image
css={styles.icon}
src="./congress-2023/contact-4.svg"
alt="Fundación amar contacto"
/> */
}
