import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { breakpoints } from "@joinlean/utils-react/dist/ui";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  // height: 80vh;
  // width: 100%;
  // position: relative;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const container = css`
  ${ui.column(0)};
  ${ui.flexCenter()};

  ${getMediaMin(breakpoints.lg)(`
    ${ui.row("md")};
  `)}
`;

export const columnLeft = css`
  order: 0;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 50%;
  }
`;

export const columnRight = css`
  order: 1;
  width: 100%;

  ${getMediaMin("lg")(`
    order: 0;
    width: 50%;
  `)}
`;

export const imageMain = css`
  margin-left: auto;
  width: 100%;
  max-width: ${ui.rem(400)};
  margin: auto;

  ${getMediaMin("md")(`
    max-width: ${ui.rem(500)};
    margin: auto;
  `)}

  ${getMediaMin("lg")(`
    margin-right: -100px;
    max-width: ${ui.rem(600)};
  `)}
`;

export const decorationLeafs = ({
  left,
  top,
  size,
  random,
}: {
  left: number;
  top: number;
  size: number;
  random: number;
}) => {
  return css`
    position: absolute;
    z-index: 2;
    max-width: ${ui.rem(size)};
    animation: animationLeaf2 10s ease-in forwards;
    top: ${top}%;
    opacity: 0;
    left: ${((left - 60) / 30) * 90}vw;

    ${getMediaMin("lg")(`
      left: ${left}vw;
    `)}

    @keyframes animationLeaf2 {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      70% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        top: 100%;
      }
    }
  `;
};

// ALL ONS

// export const imageTree = css`
//   position: absolute;
//   bottom: 0px;
//   right: calc(${ui.rem("sm")} * -1);
// `;

// export const container = css`
//   height: 100%;
//   ${ui.row("md")};
//   ${ui.flexCenter()};
//   position: relative;
// `;

// export const extraSpace = css`
//   width: 100%;
// `;
