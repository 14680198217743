import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  ${ui.column("xl")}
  align-items: center;

  ${getMediaMin("lg")(`
    ${ui.row("xl")}
  `)};
`;

export const card = () => {
  return css`
    ${ui.column("xs")}
    background: #ECC648;
    border-radius: 90px;
    padding: 30px 60px;
    align-items: flex-start;
  `;
};

export const icon = () => {
  return css`
    img {
      max-height: 24px;
      width: auto;
    }

    ${getMediaMin("lg")(`
      img {
        max-height: 34px;
        width: auto;
      }
    `)};
  `;
};

export const title = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #fffdfd;

    ${getMediaMin("lg")(`
      font-size: 30px;
    `)};
  `;
};

export const link = () => {
  return css`
    ${title()}
    ${ui.row("xs")};
    ${ui.flexCenter()};
    font-size: 16px;
    color: #795f0a;

    ${getMediaMin("sm")(`
      font-size: 20px;
    `)};

    ${getMediaMin("lg")(`
      font-size: 22px;
    `)};
  `;
};

export const grid = () => {
  return css`
    ${ui.row("sm")}
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  `;
};

export const grid2 = () => {
  return css`
    ${ui.row("xl")}
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  `;
};

export const gridTitle = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #5b5b5f;
    padding-top: 20px;
  `;
};

export const img = () => {
  return css`
    max-height: 150px;
    width: auto;

    img {
      max-height: 150px;
      width: auto;
    }
  `;
};
