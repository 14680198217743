import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  height: ${ui.rem(900)};
  width: 100%;
  position: relative;
  overflow: hidden;

  ${getMediaMin("md")(`
    height: ${ui.rem(800)};
  `)}
}
`;

export const decorationCloud = css`
  position: absolute;
  bottom: ${ui.rem("lg")};
  right: calc(${ui.rem("md")} * -1);
  max-width: ${ui.rem(150)} !important;
`;

export const decorationBee = css`
  position: absolute;
  top: 0px;
  left: calc(${ui.rem("sm")} * -1);
`;

export const container = css`
  height: 100%;
  ${ui.row("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css`
  max-width: none;
`;
