/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  Button,
  DecorationLine,
  DecorationSpiral,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const DonationDonationSection = () => {
  return (
    <section css={styles.section}>
      <DecorationSpiral css={styles.decorationSpiral} />
      <DecorationLine css={styles.decorationLines} />
      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          text="Considera ser un padrino de nuestra fundación si quieres ayudarnos a brindar esperanza a pequeños que vienen de ambientes de violencia y abandono."
          direction="center"
          directionLg="center"
        />
      </ComponentContainer>

      <div css={styles.paymentContainer}>
        <div css={styles.paymentContainerBox}>
          <p css={styles.boxSubtitle}>Donativo en OXXO o Bancos</p>
          <p css={styles.boxText}>
            <strong>BANCO:</strong> BANCOMER BBVA
          </p>
          <p css={styles.boxText}>
            <strong>TARJETA:</strong> 4555 1130 1013 3868
          </p>
          <p css={styles.boxText}>
            <strong>CUENTA:</strong> 01 1894 4822
          </p>
          <p css={styles.boxText}>
            <strong>CLABE:</strong> 01 2910 0011 8944 8220
          </p>
        </div>

        <div>
          <p css={styles.boxSubtitle}>Donativo por tarjeta</p>
          <div css={styles.paymentButtons}>
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=VU7HKRVMNJ3QJ"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="sm">Donar aquí</Button>
            </a>
          </div>
        </div>
      </div>

      <ComponentContainer css={styles.containerNote}>
        <Paragraph
          css={styles.textNote}
          text={`*Si requires recibo de tu donativo contáctanos por <a href="mailto:facturacion@fundacionamar.org" target="_blank"
          rel="noreferrer">correo a facturacion@fundacionamar.org</a> o por <a href="https://wa.me/+525561833727" target="_blank"
          rel="noreferrer">WhatsApp +52 9999-47-84-34</a>`}
          direction="center"
          directionLg="center"
        />
        <Paragraph
          css={styles.textNote}
          text="*Nosotros no recopilamos su información, estos donativos se realizan directamente con el proveedor o con los bancos."
          direction="center"
          directionLg="center"
        />
      </ComponentContainer>
    </section>
  );
};
