/** @jsxImportSource @emotion/react */
import { BrowserRouter } from "react-router-dom";
import AOS from "aos";
import { css } from "@emotion/react";
import {
  GlobalReset,
  ScreenProvider,
  ScrollProvider,
  ThemeProvider,
} from "@joinlean/utils-react";
import { Routes } from "./routes";

import "animate.css";
// @ts-ignore
import "../../../node_modules/aos/dist/aos.css";
import { useEffect, useLayoutEffect } from "react";
import { Loading, ScrollToTop } from "./utils";

function App() {
  return (
    <>
      <GlobalReset />

      <BrowserRouter>
        <ScreenProvider
          breakpoints={{
            xs: 356,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1350,
          }}
        >
          <ScrollProvider>
            <ThemeProvider>
              <Main>
                <main
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Routes />
                </main>
              </Main>
            </ThemeProvider>
          </ScrollProvider>
        </ScreenProvider>
      </BrowserRouter>
    </>
  );
}

export const Main = ({ children }: { children: any }) => {
  useEffect(() => {
    const html = document.getElementsByTagName("html");

    if (html.length > 0) {
      html[0].setAttribute("lang", "es");
    }

    AOS.init({
      duration: 600,
    });
  }, []);

  useLayoutEffect(() => {
    AOS.refresh();
  });

  return (
    <>
      <Loading />
      <ScrollToTop />
      {children}
    </>
  );
};

export default App;
