/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { DecorationXs, Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const DonationKidsSection = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <DecorationXs css={styles.decorationXs} />
        <Image
          css={styles.imgBg}
          src="./files/donation-3.svg"
          alt="Fundación amar diseño donación"
        />

        <Paragraph
          css={styles.text}
          text="Apoya nuestra causa para mejorar la vida de niños y niñas de Yucatán."
          direction="center"
          directionLg="left"
        />
        <Image
          css={styles.img}
          src="./files/donation-2.png"
          alt="Fundación amar niños en un árbol"
        />
        <Paragraph
          css={styles.text}
          text="Así lograremos que puedan reintegrarse a una familia."
          direction="center"
          directionLg="right"
        />
      </ComponentContainer>
    </section>
  );
};
