/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  Image,
  Paragraph,
  DecorationDots,
  DecorationSpiral,
} from "../../../../components";

import * as styles from "./styles";

export const HomePresentationSection = () => {
  return (
    <section css={styles.section}>
      <DecorationDots css={styles.decorationDots} />
      <DecorationSpiral delay={1000} css={styles.decorationSpirals} />

      <Image src="./files/home-2.png" alt="Decoración" />
      <div css={styles.beforeContainer}>
        <ComponentContainer css={styles.container}>
          <Image
            width={300}
            src="./files/logo-white.svg"
            alt="Fundación amar logo blanco"
          />
          <Paragraph
            css={styles.text}
            title="¿Qué es Amar?"
            text={`
            Nuestra <strong>misión</strong> es restituir a las niñas y niños que son acogidos en Casa Amar, por medio de una intervención integral, todos los derechos que se hayan vulnerado como resultado de la violencia y el descuido parental, en lo que se resuelve su situación jurídica y pueden integrarse a una familia. 
            </br>
            <strong>Visión</strong> es ser un referente nacional de crianza positiva y con perspectiva de derechos de infancia cuyo impacto no se limite a las niñas y niños que reciban acogimiento temporal, sino ir un paso más allá, fortaleciendo a las familias adoptivas o a la red familiar.
            `}
            theme="light"
            direction="center"
            directionLg="center"
          />
        </ComponentContainer>
      </div>

      <Image src="./files/home-3.png" alt="Decoración" />
    </section>
  );
};
