/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const ContactBannerSection = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <a
          href="https://goo.gl/maps/RDArv75529qwnCGs7"
          target="_blank"
          rel="noreferrer"
          css={styles.containerMap}
        >
          <Paragraph
            css={styles.title}
            title="Ubicación"
            direction="left"
            directionLg="left"
          />

          <Image
            css={styles.imgMap}
            src="./files/contact-1.png"
            alt="Fundación amar mapa"
          />
        </a>

        <Image
          css={styles.imgBottom}
          src="./files/contact-2.png"
          alt="Efecto de círculo"
        />

        <Image
          css={styles.imgBaby}
          src="./files/contact-3.png"
          alt="Bebé gateando"
        />

        <Image
          css={styles.imgBall}
          src="./files/contact-4.png"
          alt="Pelota de playa"
        />
      </ComponentContainer>
    </section>
  );
};
