/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  DecorationBee,
  DecorationCloud,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const HomeHowDoWeDoItSection = () => {
  return (
    <section css={styles.section}>
      <DecorationBee css={styles.decorationBee} />
      <DecorationCloud css={styles.decorationCloud} />
      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          title="¿Cómo lo hacemos
          posible?"
          text={`
          Para llegar al cumplimiento de nuestros objetivos, contamos con el modelo LAN, replicado de “La Alegría de los Niños IAP” quienes nos han brindado todos los programas, manuales, protocolos, capacitación y acompañamiento diario. Este modelo de intervención social tiene 28 años operando de manera exitosa en Querétaro, y fue elegido por <strong>Fundación AMAR</strong> por responder a las necesidades que se detectaron en la niñez vulnerada de Yucatán.
          </br>
          Trabajando con mucho cariño en cada una de estas áreas:
          `}
          direction="center"
          directionLg="center"
        />
      </ComponentContainer>
    </section>
  );
};
