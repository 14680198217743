import { css } from "@emotion/react";
import { ThemeSizesTypes, ui } from "@joinlean/utils-react";

export type ContainerHalfDirectionType = "left" | "right";

export const div = (
  space: number | ThemeSizesTypes,
  direction: ContainerHalfDirectionType
) => {
  const getMargin = () => {
    if (direction === "left") {
      return `
        margin-left: auto;
        align-items: flex-start;
      `;
    } else {
      return `
        margin-right: auto;
        align-items: flex-end;
      `;
    }
  };

  return css`
    max-width: calc(${ui.rem(1400 / 2)} - ${ui.rem(space)} / 2);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--space-md);

    @media (min-width: ${ui.breakpoints.lg}px) {
      ${getMargin()};
    }
  `;
};
