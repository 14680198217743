import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  position: relative;
`;

export const beforeContainer = css`
  background: #ffec80;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css`
  max-width: ${ui.rem(900)};
`;

export const decorationLion = css`
  position: absolute;
  top: calc(${ui.rem("xl")} * -1);
  left: 0px;
  max-width: ${ui.rem(150)};

  ${getMediaMin("md")(`
    max-width: ${ui.rem(200)};
  `)}
`;
