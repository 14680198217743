/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  AnimationTreeAndBirds,
  Button,
  DecorationCloud,
  DecorationW,
  DecorationXs,
} from "../../../../components";

import * as styles from "./styles";

export const HomeBannerSection = () => {
  return (
    <section css={styles.banner}>
      <DecorationXs css={styles.decorationXs} />
      <DecorationCloud css={styles.decorationCloud} delay={3000} />
      <DecorationW css={styles.decorationW} delay={5000} />
      <AnimationTreeAndBirds />
      <ComponentContainer css={styles.container}>
        <Button type="md" to="/donaciones">
          Dona aquí
        </Button>
      </ComponentContainer>
    </section>
  );
};
