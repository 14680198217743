import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const section = css`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const decorationEqual = css`
  position: absolute;
  left: calc(${ui.rem("lg")} * -2);
  top: 0px;
`;

export const container = css`
  ${ui.column(0)};
  ${ui.flexCenter()};

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row("md")};
  }
`;

export const columnLeft = css`
  order: 0;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 50%;
  }
`;

export const columnRight = css`
  order: 1;
  width: 100%;
  justify-content: flex-end;

  @media (min-width: ${ui.breakpoints.lg}px) {
    order: 0;
    width: 50%;
  }
`;

export const imageMain = css``;
