/** @jsxImportSource @emotion/react */

import { Image } from "../../../../components";

import * as styles from "./styles";

export const FAQBannerSection = () => {
  return (
    <section css={styles.section}>
      <Image
        css={styles.img}
        src="./files/faq-1.png"
        srcXs="./files/faq-1-sm.png"
        srcSm="./files/faq-1-sm.png"
        srcMd="./files/faq-1-sm.png"
        alt="Fundación amar niños en un árbol"
      />
    </section>
  );
};
