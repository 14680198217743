import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

// BANNER
export const banner = css`
  position: relative;
`;

export const decorationLeafs = ({
  left,
  top,
  size,
}: {
  left: number;
  top: number;
  size: number;
}) => css`
  position: absolute;
  left: ${left}vw;
  z-index: 1;
  max-width: ${ui.rem(size)};
  animation: animationLeaf1 10s ease-in forwards;
  top: ${top}%;

  @keyframes animationLeaf1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: 100%;
    }
  }
`;

export const bannerSuperior = css`
  height: ${ui.rem(200)};
  width: 100%;
  // background-color: #ffec80;
  background-image: URL("./files/home-15.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const decorationW = css`
  position: absolute;
  top: 0px;
  left: calc(${ui.rem("lg")} * -1.5);
  transform: rotate(180deg);

  * {
    fill: #ae8dcc;
  }
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  margin-top: calc(${ui.rem("xl")} * 1);
  margin-bottom: calc(${ui.rem("xl")} * 1);
`;
