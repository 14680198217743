import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
  padding-top: 0px;
`;

export const containerMap = css`
  ${ui.column("sm")};
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: ${ui.rem(900)};

  ${getMediaMin("md")(`
    max-width: ${ui.rem(600)};
  `)}

  ${getMediaMin("lg")(`
    max-width: ${ui.rem(900)};
    margin-left:${ui.rem(100)};
  `)};
`;

export const imgMap = css`
  width: 100%;
  max-width: ${ui.rem(1200)};
  z-index: 1;
`;

export const title = css`
  width: auto;
  max-width: ${ui.rem(800)};
  margin-right: auto;
`;

export const imgBottom = css`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const imgBaby = css`
  position: absolute;
  left: 0px;
  bottom: 0px;
  max-width: ${ui.rem(150)};
  z-index: 2;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const imgBall = css`
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: ${ui.rem(100)};
  z-index: 2;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;
