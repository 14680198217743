import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  max-height: ${ui.rem(900)};
  margin-top: ${ui.rem("xl")};
  margin-bottom: ${ui.rem("xl")};
  ${ui.column("lg")};
  ${ui.flexCenter()};
  overflow: hidden;
  position: relative;
`;

export const decorationSpiral = css`
  position: absolute;
  left: ${ui.rem(-50)};
  top: ${ui.rem(100)};

  * {
    fill: #b99cd4;
  }

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const decorationCloud = css`
  position: absolute;
  right: ${ui.rem(-50)};
  bottom: ${ui.rem(100)};

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const containerTop = css`
  ${ui.column("xs")}
  ${ui.flexCenter()};
  position: relative;
  width: 100%;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")}
  ${ui.flexCenter()};
  position: relative;
  max-width: ${ui.rem(800)};
`;

export const imgBg = css`
  width: 100%;
  z-index: -1;
  max-width: ${ui.rem(1000)};
`;

export const textNote = css``;

export const containerButton = css`
  height: 100%;
  ${ui.column("lg")}
  ${ui.flexCenter()};
  position: relative;
  max-width: ${ui.rem(800)};
`;

// MODULE QUESTION
export const questionContainer = css`
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  position: relative;

  ${getMediaMin("lg")(`
  padding: 0px ${ui.rem("md")};
  `)}
`;

export const questionButton = css`
  background-color: #ffffff;
  padding: ${ui.rem(12)} ${ui.rem("sm")};
  border-radius: ${ui.rem("xs")};
  width: 100%;
  color: #4e4e4e;
  box-shadow: 2px 2px 5px #84838373;
  font-weight: 800;
  text-align: left;
  font-size: ${ui.rem(18)};
  ${ui.row("md")};
  justify-content: space-between;
  cursor: pointer;
`;
export const questionList = (open: boolean) => css`
  position: absolute;
  z-index: 1;
  ${ui.column("sm")};
  background-color: #ffffff;
  padding: ${ui.rem(12)} ${ui.rem("sm")};
  border-radius: ${ui.rem("xs")};
  width: calc(100% - ${ui.rem("md")} - ${ui.rem("md")});
  top: ${ui.rem(50)};
  box-shadow: 2px 2px 5px #84838373;
  overflow-y: scroll;
  max-height: ${ui.rem(500)};
  ${open ? "display: flex;" : "display: none;"}
`;

export const questionListItem = css`
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 500;
  font-size: ${ui.rem(18)};
  line-height: ${ui.rem(22)};
  width: 100%;
  color: #7f7f7f;
  font-weight: 500;
  text-align: left;
  font-size: ${ui.rem(16)};
  cursor: pointer;
  padding: ${ui.rem("xs")} ${ui.rem("md")};

  :hover {
    color: #4e4e4e;
  }
`;
export const questionAnswer = css`
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 500;
  font-size: ${ui.rem(18)};
  line-height: ${ui.rem(22)};
  background-color: #ffffff;
  padding: ${ui.rem(30)} ${ui.rem("md")};
  margin-top: ${ui.rem("sm")};
  border-radius: ${ui.rem("xs")};
  width: calc(100%);
  top: ${ui.rem(50)};

  border: 1px solid #84838332;
`;
