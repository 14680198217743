import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const page = css`
  ${ui.column("lg")};
  background-color: #ffffff;
  overflow: hidden;
`;

export const header = css`
  background: linear-gradient(180deg, #ffe5c4 0%, #ffffff 50%);
`;
