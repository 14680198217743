import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax } from "../../../../utils";

export const section = css`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const decorationSpiral = css`
  position: absolute;
  top: 0px;
  right: calc(45vw);
  z-index: 1;
  width: ${ui.rem(100)};

  * {
    fill: #f4a664;
  }

  ${getMediaMax("lg")(`
      display: none;
  `)}
`;

export const imageBall = css`
  position: absolute;
  right: calc(${ui.rem("sm")} * -1);

  @media (min-width: ${ui.breakpoints.lg}px) {
    bottom: 0px;
  }
`;

export const container = css`
  ${ui.column(0)};
  ${ui.flexCenter()};

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row("md")};
  }
`;

export const columnLeft = css`
  order: 0;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 50%;
  }
`;

export const columnRight = css`
  order: 1;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    order: 0;
    width: 50%;
  }
`;

export const imageMain = css``;
