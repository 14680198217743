import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  margin-bottom: ${ui.rem("lg")};
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
  padding-top: 0px;
  align-items: center;
`;

export const titleContainer = (withDecoration: boolean) => css`
  position: relative;
  margin-top: ${withDecoration ? "200px" : "0px"};
  width: 477px;
  min-width: 477px;
  max-width: 477px;
  height: 311px;
  min-height: 311px;
  max-height: 311px;
`;

export const textContainer = css`
  ${ui.column(14)};
  position: absolute;
  bottom: 0px;
  height: 190px;
  width: 477px;
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  color: #da7059;
  z-index: 1;
  text-shadow: -6px 0 #ffffff, 0 6px #ffffff, 6px 0 #ffffff, 0 -6px #ffffff;

  ${getMediaMin("lg")(`
    font-size: 60px;
  `)}
`;

export const subtitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  color: #da7059;
  z-index: 1;
  text-shadow: -6px 0 #ffffff, 0 6px #ffffff, 6px 0 #ffffff, 0 -6px #ffffff;

  ${getMediaMin("lg")(`
    font-size: 45px;
  `)}
`;

export const imgDecoration = css`
  position: absolute;
  z-index: 0;
  top: 50px;
  margin-right: 300px;
  max-width: 400px;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const imgBg = css`
  opacity: 0.5;
`;
