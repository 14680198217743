import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const banner = css`
  max-height: ${ui.rem(900)};
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const decorationXs = css`
  position: absolute;
  top: ${ui.rem("xl")};
  left: 0px;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const decorationCloud = css`
  position: absolute;
  top: ${ui.rem("lg")};
  right: 0px;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const decorationW = css`
  position: absolute;
  bottom: 0px;
  right: ${ui.rem("xl")};

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const imgContainer = css`
  width: 100%;
  max-width: ${ui.rem(1200)};
`;

export const img = css`
  width: 100%;
  height: 100%;
`;
