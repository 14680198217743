import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  padding-top: 50px;
  padding-bottom: 200px;
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  ${ui.column("lg")}
  position: relative;
  padding-top: 0px;
  align-items: center;
`;

export const button = css`
  cursor: pointer;
  background: #7c45be;
  border-radius: 39px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  padding: ${ui.rem(15)} ${ui.rem(30)};

  :hover {
    background: #703cae;
  }

  :active {
    background: #64359e;
  }
`;

export const columns = () => {
  return css`
    ${ui.column("md")}
    align-items: center;

    ${getMediaMin("lg")(`
      ${ui.row("md")}
    `)}

    * {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;

      color: #808080;
    }
  `;
};

export const columnsLeft = () => {
  return css`
    ${ui.column("sm")}

    * {
      font-family: "Montserrat";
      font-weight: 400;
      font-style: normal;
      font-size: 30px;
      color: #808080;
    }
  `;
};

export const text = (
  color: "orange" | "gray" | "green" | "yellow",
  strong = false
) => {
  const getColor = () => {
    if (color === "orange") return "#DA7059";
    if (color === "gray") return "#808080";
    if (color === "green") return "#43A492";
    if (color === "yellow") return "#E1B932";
  };

  return css`
    & {
      color: ${getColor()};
      ${strong ? `font-weight: 700;` : ``}
    }
  `;
};

export const textSmall = () => {
  return css`
    & {
      font-size: 22px;
    }
  `;
};

export const img = css`
  max-width: 350px;

  ${getMediaMin("lg")(`
    max-width: 450px;
  `)}
`;
