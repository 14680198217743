/** @jsxImportSource @emotion/react */

import * as styles from "./styles";

export interface ColumnProps {
  css?: any;
  className?: string;
  children?: any;
}

export const Column = ({ className = "", children }: ColumnProps) => {
  return (
    <div css={styles.div} className={className}>
      {children}
    </div>
  );
};
