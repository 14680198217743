import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax } from "../../../../utils";

export const section = css`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const imageBall = css`
  position: absolute;
  right: calc(${ui.rem("sm")} * -1);

  ${getMediaMax("lg")(`
    display: none;
  `)}

  @media (min-width: ${ui.breakpoints.lg}px) {
    bottom: 0px;
  }
`;

export const container = css`
  ${ui.column(0)};
  ${ui.flexCenter()};

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row("md")};
  }
`;

export const columnLeft = css`
  order: 1;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    order: 0;
    width: 50%;
    // width: 60%;
  }
`;

export const columnRight = css`
  order: 0;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 50%;
  }

  // @media (min-width: ${ui.breakpoints.lg}px) {
  //   width: 35%;
  // }

  // @media (min-width: ${ui.breakpoints.xl}px) {
  //   width: 30%;
  // }
`;

export const imageMain = css``;

export const extraSpace = css`
  width: 0%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 5%;
  }

  @media (min-width: ${ui.breakpoints.xl}px) {
    width: 10%;
  }
`;
