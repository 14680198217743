/** @jsxImportSource @emotion/react */
import { useLayoutEffect, useState, memo } from "react";

// import ReactBodymovin from "react-bodymovin";

import * as styles from "./styles";

export interface DecorationDotsProps {
  css?: any;
  className?: string;
  delay?: number;
}

export const DecorationDots = memo(
  ({ className = "", delay }: DecorationDotsProps) => {
    const [id] = useState(`${Math.random() * 20000}`);

    useLayoutEffect(() => {
      // @ts-ignore
      const instance = bodymovin.loadAnimation({
        container: document.getElementById(`animation-${id}`),
        path: "./files/animation-4.json",
        renderer: "svg",
        loop: true,
        autoplay: false,
        name: "Animation Dots",
      });

      if (delay) {
        setTimeout(function () {
          instance.play();
        }, delay);
      } else {
        instance.play();
      }

      // Return clean up function here
      return () => instance.destroy();
    });

    return (
      <div
        id={`animation-${id}`}
        className={`${className}`}
        css={[styles.decoration]}
      ></div>
    );
  }
);
