import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

// BANNER
export const section = css`
  position: relative;
`;

export const beforeContainer = css`
  background: #efd95f;
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css`
  max-width: none;
`;

export const decorationDots = css`
  position: absolute;
  top: ${ui.rem("xl")};
  left: 0px;
`;

export const decorationSpirals = css`
  position: absolute;
  top: ${ui.rem("xl")};
  right: 0px;

  @media (min-width: ${ui.breakpoints.xl}px) {
    top: 50%;
  }

  * {
    fill: #d5be39;
  }
`;
