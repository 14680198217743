/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const CallToAction = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <a
          href="http://amar2023.registrodeeventos.com/"
          target="_blank"
          rel="noreferrer"
          css={styles.button}
        >
          Registrarme Aquí
        </a>
        <div css={styles.columns()}>
          <div css={styles.columnsLeft()}>
            <p>
              <strong css={styles.text("orange", true)}>Inscripción:</strong>{" "}
              $2,500.
            </p>
            <p css={styles.textSmall()}>*El precio no incluye los talleres.</p>
            <p>
              <strong css={styles.text("green", true)}>Preventa:</strong> $1,750{" "}
              <span css={styles.textSmall()}>*Hasta el 28 de Febrero.</span>
            </p>
            <p>
              <strong css={styles.text("yellow", true)}>Estudiantes:</strong>{" "}
              $1,500.
            </p>
            <p>
              <strong css={styles.text("orange", true)}>
                Empleados del Gobierno:
              </strong>{" "}
              $1,250.
            </p>
            <p>
              <strong css={styles.text("green", true)}>Talleres:</strong>{" "}
              $1,650. <span css={styles.textSmall()}>*Precio por taller.</span>
            </p>
            <p css={styles.textSmall()}>
              *Para poder asistir a los talleres debes estar inscrito y haber
              pagado tu participación al Congreso.
            </p>
            <p css={styles.textSmall()}>
              *Parte de tu inscripción irá directo al apoyo de los niños de la
              FUNDACIÓN AMAR.
            </p>
          </div>
          <Image
            css={styles.img}
            src="./congress-2023/call-to-action.png"
            alt="Fundación amar mapa"
          />
        </div>
      </ComponentContainer>
    </section>
  );
};
