/** @jsxImportSource @emotion/react */
import { Link, useNavigate } from "react-router-dom";

import * as styles from "./styles";

export interface ButtonProps {
  to?: string;
  css?: any;
  className?: any;
  children?: any;
  onClick?: any;
  type?: "sm" | "md" | "lg";
}

export const Button = ({
  to,
  type = "md",
  className = "",
  onClick,
  children,
}: ButtonProps) => {
  const navigate = useNavigate();

  const _onClick = () => {
    onClick();
    if (to) {
      navigate(to);
    }
  };

  if (to) {
    return (
      <Link
        type="sm"
        target="_blank"
        to={to}
        className={className}
        onClick={onClick}
        css={styles.button(type)}
      >
        {children}
      </Link>
    );
  }

  return (
    <button className={className} onClick={_onClick} css={styles.button(type)}>
      {children}
    </button>
  );
};
