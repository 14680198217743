import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  max-height: ${ui.rem(900)};
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  overflow: hidden;
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
`;

export const decorationSpiral = css`
  position: absolute;
  left: 0px;
  top: 0px;
  transform: rotate(180deg);

  * {
    fill: #ff6b6b;
  }

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const decorationLines = css`
  position: absolute;
  right: -${ui.rem(60)};
  bottom: 0px;

  ${getMediaMin("lg")(`
  right: -${ui.rem(100)};
`)}
`;

export const text = css`
  max-width: ${ui.rem(800)};
`;

export const paymentContainer = css`
  background-color: #f18063;
  position: relative;
  padding: ${ui.rem("sm")} calc(${ui.rem("sm")});
  margin: ${ui.rem("sm")} calc(${ui.rem("sm")});
  margin-left: ${ui.rem("md")};
  ${ui.column("lg")};

  ${getMediaMin("sm")(`
  padding: ${ui.rem("md")} calc(${ui.rem("md")});
`)}

  ${getMediaMin("md")(`
    padding: ${ui.rem("xl")} calc(${ui.rem("lg")} * 2);
  `)}

  ::before {
    background-color: #df6140;
    position: absolute;
    width: 100%;
    height: 100%;
    left: calc(${ui.rem("sm")} * -1);
    top: calc(${ui.rem("sm")} * -1);
    content: "";
    z-index: -1;
  }
`;

export const paymentContainerBox = css``;

const _textStyle = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  text-align: center;
  color: #ffffff;

  @media (min-width: ${ui.breakpoints.lg}px) {
    text-align: center;
  }

  strong {
    font-weight: 500;
  }
`;

export const boxSubtitle = css`
  ${_textStyle};
  font-size: ${ui.rem(20)};
  font-weight: 800;
  margin-bottom: ${ui.rem("sm")};

  @media (min-width: ${ui.breakpoints.md}px) {
    font-size: ${ui.rem(30)};
  }
`;

export const boxText = css`
  ${_textStyle};
  font-size: ${ui.rem(20)};
  margin-bottom: ${ui.rem("sm")};

  @media (min-width: ${ui.breakpoints.md}px) {
    font-size: ${ui.rem(20)};
  }

  strong {
    font-weight: 800;
  }
`;

export const paymentButtons = css`
  ${ui.column("sm")};
  ${ui.flexCenter()};

  ${getMediaMin("xs")(`
    ${ui.row("sm")};
  `)}
`;

export const containerNote = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
`;

export const textNote = css`
  * {
    font-style: italic;
    font-size: ${ui.rem(16)};
    line-height: ${ui.rem(20)};
  }
`;
