/** @jsxImportSource @emotion/react */
import { Scaffold } from "../../components";
import {
  DonationBannerSection,
  DonationKidsSection,
  DonationDonationSection,
} from "./sections";

import * as styles from "./styles";

export const DonationPage = () => {
  return (
    <Scaffold css={styles.page}>
      <DonationBannerSection />
      <DonationKidsSection />
      <DonationDonationSection />
    </Scaffold>
  );
};
