/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Sponsors = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <div css={styles.header()}>
          <h3 css={styles.title()}>Sin su apoyo esto no sería posible</h3>
          <p css={styles.subtitle()}>¡Gracias!</p>
        </div>
        <div css={styles.grid()}>
          <Image
            css={styles.img}
            src="./congress-2023/sponsors-1.png"
            alt="Fundación amar patrocinador"
          />
          <Image
            css={styles.img}
            src="./congress-2023/sponsors-2.png"
            alt="Fundación amar patrocinador"
          />
          <Image
            css={styles.img}
            src="./congress-2023/sponsors-3.png"
            alt="Fundación amar patrocinador"
          />
          <Image
            css={styles.img}
            src="./congress-2023/sponsors-4.png"
            alt="Fundación amar patrocinador"
          />
        </div>
      </ComponentContainer>
    </section>
  );
};
