/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";

import * as styles from "./styles";

export const Workshops = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <CalendarDay
          title="27 de marzo"
          color="#7BC7B9"
          card={{
            time: "17:00 hrs.",
            title: "Tormentas emocionales ¿cómo calmarnos-los?",
            subtitle:
              "Metodología Legus Serious Play para trabajar la experiencia de estrés de los niños.",
            person: "Dra Leila Jorquera.",
          }}
        />

        <CalendarDay
          title="28 de marzo"
          color="#EDC748"
          card={{
            time: "17:00 hrs.",
            title: "La construcción de metáforas en el videofeedback parental.",
            person: "Dr. Esteban Gómez Muzzio.",
          }}
        />

        <CalendarDay
          title="29 de marzo"
          color="#EB6D4E"
          card={{
            time: "17:00 hrs.",
            title:
              "Herramientas terapéuticas del trauma y la resiliencia parental.",
            person: "Dr. Esteban Gómez Muzzio.",
          }}
        />

        <CalendarDay
          title="30 de marzo"
          color="#7BC7B9"
          card={{
            time: "17:00 hrs.",
            title:
              "Suicidio en adolescentes, evaluación del riesgo y acompañamiento sensible.",
            person: "Dr. Lucas Raspall.",
          }}
        />

        <CalendarDay
          title="31 de marzo"
          color="#EDC748"
          card={{
            time: "17:00 hrs.",
            title: "Bases de la reparación del apego en casos de trauma.",
            person: "Dra. Inés Di Bartolo.",
          }}
        />
      </ComponentContainer>
    </section>
  );
};

interface CalendarDayProps {
  title: string;
  color: "#EB6D4E" | "#7BC7B9" | "#EDC748";
  card: CardProps;
}

const CalendarDay = (props: CalendarDayProps) => {
  return (
    <div css={styles.calendarDay()}>
      <h3 css={styles.calendarDayTitle(props?.color)}>{props?.title}</h3>
      <Card {...props.card} />
    </div>
  );
};

interface CardProps {
  time: string;
  title: string;
  person: string;
  subtitle?: string;
}

const Card = (props: CardProps) => {
  return (
    <div css={styles.card()}>
      <p css={styles.cardTime()}>{props?.time}</p>
      <p css={styles.cardTitle()}>{props?.title}</p>
      {props?.subtitle ? (
        <p css={styles.cardSubtitle()}>{props?.subtitle}</p>
      ) : (
        <></>
      )}
      <p css={styles.cardPerson()}>{props?.person}</p>
    </div>
  );
};
