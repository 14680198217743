/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

interface TitleProps {
  title: string;
  subtitle?: string;
  withDecoration?: boolean;
}

export const Title = ({
  title,
  subtitle,
  withDecoration = false,
}: TitleProps) => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        {withDecoration ? (
          <Image
            css={styles.imgDecoration}
            src="./congress-2023/title-2.svg"
            alt="Fundación amar mapa"
          />
        ) : (
          <></>
        )}
        <div css={styles.titleContainer(withDecoration)}>
          <Image
            css={styles.imgBg}
            src="./congress-2023/title-1.svg"
            alt="Fundación amar mapa"
          />
          <div css={styles.textContainer}>
            <h2 css={styles.title}>{title}</h2>
            {subtitle ? <p css={styles.subtitle}>{subtitle}</p> : <></>}
          </div>
        </div>
      </ComponentContainer>
    </section>
  );
};
