/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";

import {
  AnimationLeaf,
  Button,
  DecorationW,
  Image,
  Paragraph,
} from "../../../../components";
import { useAnimationLeaf } from "../../../../utils";

import * as styles from "./styles";

export const HomeFinalSection = () => {
  const { leafs } = useAnimationLeaf({});

  return (
    <section css={styles.banner}>
      {leafs.map((leaf) => {
        return (
          <AnimationLeaf
            key={leaf.id}
            delay={leaf.delay}
            version={leaf.version}
            css={styles.decorationLeafs({
              left: leaf.left,
              top: leaf.top,
              size: leaf.size,
            })}
          />
        );
      })}

      <div css={styles.bannerSuperior}>
        <DecorationW css={styles.decorationW} />
      </div>
      <ComponentContainer css={styles.container}>
        <Image src="./files/logo.svg" alt="Fundación amar logo" />
        <Paragraph
          text="Nuestro futuro está en los niños. Brindémosles una infancia feliz y plena."
          direction="center"
          directionLg="center"
        />
        <Button to="/donaciones">Dona aquí</Button>
      </ComponentContainer>
    </section>
  );
};
