/** @jsxImportSource @emotion/react */
import {
  AnimationGoodHome,
  Column,
  ContainerHalf,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const HomeGoodHomeSection = () => {
  return (
    <div>
      <section css={styles.section}>
        <Image
          css={styles.imageBall}
          width={80}
          src="./files/home-8.png"
          alt="Fundación amar niños en un árbol"
        />

        <div css={styles.container}>
          <Column css={styles.columnLeft}>
            <AnimationGoodHome />
          </Column>

          <Column css={styles.columnRight}>
            <ContainerHalf space={"md"} direction="right">
              <Paragraph
                title="Vivienda digna"
                text="Brindamos a los niños un hogar cálido y seguro donde podrán cubrir sus necesidades básicas y evitar los peligros de una vida en la calle.
         "
                direction="center"
                directionLg="right"
              />
            </ContainerHalf>
          </Column>
          {/* <div css={styles.extraSpace}></div> */}
        </div>
      </section>
    </div>
  );
};
