import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../utils";

export const inputContainer = css`
  ${ui.column("xs")};
  align-items: flex-start;

  ${getMediaMax("md")(`
    align-items: flex-start;
  `)}

  ${getMediaMin("md")(`
    ${ui.row("sm")};
    ${ui.flexCenter()};
    align-items: flex-start;
  `)}
`;

export const inputLabel = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: #111827;

  ${getMediaMin("md")(`
  width: ${ui.rem(200)};
`)}
`;

export const input = css`
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7d7d7d;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  z-index: 1;

  ::placeholder {
    color: #d1d1d1;
  }

  :focus-visible {
    outline: none;
  }
`;

export const inputTextArea = css`
  height: ${ui.rem(100)};
`;
