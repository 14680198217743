/** @jsxImportSource @emotion/react */
import * as styles from "./styles";

export interface ParagraphProps {
  title?: string;
  className?: string;
  css?: any;
  text?: string;
  theme?: styles.ParagraphThemeType;
  direction?: styles.ParagraphDirectionType;
  directionLg?: styles.ParagraphDirectionType;
  width?: string | number;
  type?: "half" | "full";
}

export const Paragraph = ({
  title,
  text,
  className = "",
  theme = "dark",
  direction = "left",
  directionLg = "left",
  width = 600,
  type = "half",
}: ParagraphProps) => {
  return (
    <div
      className={className}
      css={styles.texts({ direction, type, directionLg, width })}
    >
      {title && (
        <h2 css={styles.title({ direction, directionLg, theme })}>{title}</h2>
      )}
      {text && (
        <p
          css={styles.text({ direction, directionLg, theme })}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
};
