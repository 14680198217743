import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../utils";

export const footer = css``;

export const beforeContainer = css`
  background-color: #fff6a0;
  width: 100%;
  padding-bottom: ${ui.rem(120)};
`;

export const container = css`
  ${ui.column("xl")};

  ${getMediaMin("lg")(`
  ${ui.column("md")};
  `)}
`;

export const containersContent = css`
  ${ui.column("md")};
  justify-content: space-between;

  ${getMediaMin("lg")(`
    ${ui.row("md")};
    align-items: flex-start;
  `)}
`;

export const containerSon = css`
  ${ui.row("md")};
  ${ui.flexCenter()};

  &,
  a,
  span {
    font-family: "Solway";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #4e4e4e;
  }

  i {
    font-size: 18px;
  }

  &.links-social {
    i {
      font-size: 20px;
    }
  }
`;

export const containerColumns = css`
  ${ui.row("xs")};
  ${ui.flexCenter()};
`;

export const linkTwoRows = css`
  ${ui.column("sm")};

  a,
  span {
    ${ui.row("xs")};
    ${ui.flexCenter()};
    text-align: center;
  }
`;
