import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const decoration = css`
  max-width: ${ui.rem(120)};
  width: 100%;

  @media (min-width: ${ui.breakpoints.md}px) {
    max-width: ${ui.rem(200)};
  }
`;
