/** @jsxImportSource @emotion/react */
import {
  AnimationRocket,
  Column,
  ContainerHalf,
  DecorationLine,
  DecorationSpiral,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const HomeHumanFormationSection = () => {
  return (
    <div>
      <section css={styles.section}>
        <DecorationSpiral css={styles.decorationSpiral} />
        <DecorationLine css={styles.decorationLine} />

        <div css={styles.container}>
          <Column css={styles.columnLeft}>
            <AnimationRocket css={styles.imageMain} />
            {/* <Image
              css={styles.imageMain}
              src="./files/home-13.png"
              alt="Fundación amar niños en un árbol"
            /> */}
          </Column>

          <Column css={styles.columnRight}>
            <ContainerHalf space={"md"} direction="right">
              <Paragraph
                title="Formación humana"
                text="Llevamos a cabo actividades que permitan la integración familiar y social de los niños,  algo indispensable para su desarrollo personal.
         "
                direction="center"
                directionLg="right"
              />
            </ContainerHalf>
          </Column>
        </div>
      </section>
    </div>
  );
};
