/** @jsxImportSource @emotion/react */

import { ThemeSizesTypes } from "@joinlean/utils-react";
import * as styles from "./styles";

export interface ContainerHalfProps {
  direction: styles.ContainerHalfDirectionType;
  children?: any;
  space?: number | ThemeSizesTypes;
}

export const ContainerHalf = ({
  children,
  space = 0,
  direction,
}: ContainerHalfProps) => {
  return <div css={styles.div(space, direction)}>{children}</div>;
};
