import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  padding: ${ui.rem("md")};
`;

export const container = css`
  height: 100%;
  ${ui.row("md")};
  ${ui.flexCenter()};
  position: relative;
  padding-top: 0px;
  justify-content: flex-end;
  align-items: center;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const list = css`
  ${ui.row("md")}
`;

export const link = css`
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #eb6d4e;
  transition: font-weight 300ms;

  :hover {
    font-weight: 700;
  }
`;

export const socialContainer = css`
  ${ui.row("sm")};
  background: #7c45be;
  border-radius: 142px;
  padding: 10px;
`;

export const social = css`
  img {
    width: 24px;
  }
`;
