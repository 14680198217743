import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { flexCenter } from "@joinlean/utils-react/dist/ui";
import { getMediaMin } from "../../../../utils";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  ${ui.column("md")}
  align-items: center;
  padding-bottom: 0px;
`;

export const texts = () => {
  return css`
    ${ui.column("xs")};
    ${ui.flexCenter()};
    cursor: pointer;
  `;
};

export const text1 = () => {
  return css`
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #808080;
  `;
};

export const text2 = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f77157;
  `;
};

export const text3 = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #5b5b5f;
  `;
};

export const imgLogo = () => {
  return css`
    max-width: 300px;
  `;
};

export const imgDecoration = () => {
  return css`
    width: 100%;
  `;
};
