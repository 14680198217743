/** @jsxImportSource @emotion/react */
import {
  Column,
  ContainerHalf,
  DecorationSpiral,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const HomeHealthySection = () => {
  return (
    <div>
      <section css={styles.section}>
        <DecorationSpiral css={styles.decorationSpiral} />
        <div css={styles.container}>
          <Column css={styles.columnLeft}>
            <ContainerHalf space={"md"} direction="left">
              <Paragraph
                title="Salud física
                y nutrición"
                text="La rehabilitación de la salud de los niños es de suma importancia, ya que podría estar seriamente deteriorada a causa de las condiciones en las que vivían."
                direction="center"
                directionLg="left"
              />
            </ContainerHalf>
          </Column>

          <Column css={styles.columnRight}>
            <Image
              css={styles.imageMain}
              src="./files/home-9.png"
              alt="Fundación amar niños en un árbol"
            />
          </Column>
        </div>
      </section>
    </div>
  );
};
