/** @jsxImportSource @emotion/react */
import {
  ComponentContainer,
  useScreenListener,
  useScrollListener,
} from "@joinlean/utils-react";
import { useEffect, useLayoutEffect, useState } from "react";
import { LogoComponent } from "../Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";

import * as styles from "./styles";
import { Button } from "../Button";

export interface AppbarProps {
  theme?: styles.Theme;
}

interface Page {
  href: string;
  name: string;
}

export const Appbar = ({ theme = "transparent" }: AppbarProps) => {
  const { resizing } = useScreenListener();
  const { scroll } = useScrollListener();
  const [open, setIsOpen] = useState<styles.OpenTypes>("close-full");
  const [appbarDown, setAppbarDown] = useState(false);
  const [pages] = useState<Page[]>([
    {
      href: "/",
      name: "Niños",
    },
    {
      href: "/voluntarios",
      name: "Voluntarios",
    },
    {
      href: "/preguntas",
      name: "Preguntas",
    },
    {
      href: "/contacto",
      name: "Contacto",
    },
  ]);

  const onClose = () => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", ``);

    setIsOpen("closing");

    setTimeout(() => {
      setIsOpen("close");
    }, 100);

    setTimeout(() => {
      setIsOpen("close-full");
    }, 700);
  };

  const onOpen = () => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", `overflow:hidden;`);

    setIsOpen("opening");

    setTimeout(() => {
      setIsOpen("open");
    }, 100);
  };

  useEffect(() => {
    if (window.scrollY >= 50) {
      setAppbarDown(true);
    } else {
      setAppbarDown(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (open === "open" || open === "opening") {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizing]);

  useLayoutEffect(() => {
    getMenuData();
  }, []);

  const getMenuData = async () => {};

  return (
    <header css={styles.header(appbarDown, theme)} className="navbar container">
      <ComponentContainer css={styles.container}>
        <>
          <NavbarDesktop
            pages={pages}
            onClose={onClose}
            appbarDown={appbarDown}
          />
          <NavbarMobile
            pages={pages}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
          />
        </>
      </ComponentContainer>
    </header>
  );
};

const NavbarDesktop = ({ onClose, pages, appbarDown }: any) => {
  const navigate = useNavigate();

  return (
    <div css={styles.navDesktop}>
      <Button
        type="sm"
        css={styles.buttonLeft}
        to="/congreso_Internacional_2023"
        onClick={() => {
          onClose();
        }}
      >
        <div>Congreso Internacional Amar</div>
        <div>
          <strong>Más información</strong>
        </div>
      </Button>

      <NavItem onClose={onClose} page={pages[0]} />
      <NavItem onClose={onClose} page={pages[1]} />
      <Link to={"/"}>
        <div onClick={onClose} css={styles.imageContainer}>
          <LogoComponent css={styles.brand(appbarDown)} />
        </div>
      </Link>
      <NavItem onClose={onClose} page={pages[2]} />
      <NavItem onClose={onClose} page={pages[3]} />

      <Button
        type="sm"
        css={styles.buttonDonation}
        onClick={() => {
          onClose();
          navigate("/donaciones");
        }}
      >
        Donar
      </Button>
    </div>
  );
};

const NavbarMobile = ({ open, pages, onClose, onOpen }: any) => {
  const navigate = useNavigate();

  return (
    <>
      {open !== "close-full" && (
        <div onClick={onClose} css={styles.layer(open)} />
      )}
      <nav
        css={styles.nav(open)}
        className={`animate__animated ${
          open === "open" || open === "opening"
            ? "animate__fadeInLeft"
            : open === "closing" || open === "close"
            ? "animate__fadeOutLeft"
            : ""
        }`}
      >
        <LogoComponent css={styles.sidebarMobileLogo} />
        <div css={styles.sidebarBody}>
          <NavItem onClose={onClose} page={pages[0]} />
          <NavItem onClose={onClose} page={pages[1]} />
          <NavItem onClose={onClose} page={pages[2]} />
          <NavItem onClose={onClose} page={pages[3]} />

          <Button
            type="sm"
            css={styles.buttonLeft}
            to="/congreso_Internacional_2023"
            onClick={() => {
              onClose();
            }}
          >
            <div>Congreso Internacional Amar</div>
          </Button>

          <Button
            type="sm"
            css={styles.buttonDonation}
            onClick={() => {
              onClose();
              navigate("/donaciones");
            }}
          >
            Donar
          </Button>
        </div>
      </nav>
      <div css={styles.appbarMobile}>
        <Link to="/" css={styles.menu}>
          <LogoComponent css={styles.appbarMobileLogo} />
        </Link>
        <div onClick={onOpen} css={styles.menu}>
          <i className="fas fa-bars fa-lg"></i>
        </div>
      </div>
    </>
  );
};

interface NavItemProps {
  css?: any;
  className?: string;
  onClose: any;
  page: Page;
  mobile?: boolean;
}

const NavItem = ({
  className = "",
  onClose,
  page,
  mobile = true,
}: NavItemProps) => {
  const router = useLocation();

  const isActive = (href: string): boolean => {
    if (href === "/") {
      if (router.pathname === "/") {
        return true;
      }
      return false;
    } else {
      return router.pathname.includes(href);
    }
  };

  const active = isActive(page.href);

  return (
    <Link className={className} to={{ pathname: page.href }}>
      <span onClick={onClose} css={styles.link(active, mobile)}>
        {page.name}
      </span>
    </Link>
  );
};
