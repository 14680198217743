import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

export const section = css`
  position: relative;
  overflow: hidden;
`;

export const decorationSpiral = css`
  position: absolute;
  top: calc(${ui.rem("xl")} * 2);
  left: calc(${ui.rem("xl")} * -1);

  * {
    fill: #b99cd4;
  }
`;

export const decorationDots = css`
  position: absolute;
  top: calc(${ui.rem("xl")} * 4);
  right: calc(${ui.rem("xl")} * -1);

  ${getMediaMin("md")(`
  right: calc(${ui.rem("xl")} * -1 - ${ui.rem(20)});
`)}
`;

export const container = css`
  height: 100%;
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const text = css`
  max-width: ${ui.rem(900)};
`;

export const formContainer = css`
  height: 100%;
  position: relative;
  margin-top: ${ui.rem("lg")};
  margin-bottom: ${ui.rem("lg")};
  max-width: 500px;
  width: 100%;

  display: grid;
  grid-gap: ${ui.rem("md")};
  grid-template-columns: 1fr;

  @media (min-width: ${ui.breakpoints.md}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const imgKid1 = css`
  position: absolute;
  top: calc(${ui.rem(110)} * -1);
  right: calc(${ui.rem(50)} * -1);

  ${getMediaMin("md")(`
    top: calc(${ui.rem(145)} * -1);
  `)}
`;

export const imgKid2 = css`
  position: absolute;
  bottom: calc(${ui.rem(100)} * 1);
  left: calc(${ui.rem(200)} * -1);

  ${getMediaMax("md")(`
    display: none;
  `)}
`;

export const imgLion = css`
  position: absolute;
  bottom: calc(${ui.rem("xl")} * -1);
  right: calc(${ui.rem(200)} * -1);

  ${getMediaMax("md")(`
    display: none;
  `)}
`;

export const textAlert = (showAlert: boolean) => css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #797268;
  max-width: ${ui.rem(600)};
  animation: alertAnimation 1s linear;

  ${showAlert
    ? `
    `
    : `
      display: none;
    `}

  @keyframes alertAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const inputContainer = css`
  ${ui.column("sm")};

  ${getMediaMax("md")(`
    align-items: flex-start;
  `)}

  ${getMediaMin("md")(`
    ${ui.row("sm")};
    ${ui.flexCenter()};
  `)}
`;

export const inputLabel = (active: boolean) => css`
  width: 100%;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  border-radius: ${ui.rem("sm")};
  padding: ${ui.rem("sm")} ${ui.rem("md")};
  transition: all 300ms;

  ${active
    ? `
    color: #ffffff;
  background-color: #f18063;
    `
    : `
    color: #717171;
  background-color: #d6d6d6;
    `}

  ${getMediaMin("md")(`
    width: ${ui.rem(200)};
  `)};
`;
