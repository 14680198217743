/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";

import * as styles from "./styles";

export const Conferences = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <CalendarDay
          title="29 de marzo"
          color="#EB6D4E"
          cards={[
            {
              time: "9:00 hrs.",
              title: "Trauma complejo y cuidado alternativo:",
              subtitle: "acogiendo el dolor de la infancia.",
              person: "Dr. Esteban Gómez Muzzio.",
            },
            {
              time: "10:30 hrs.",
              title: "Neurobiología del desarrollo: ",
              subtitle: "Maltrato, estrés tóxico y daño.",
              person: "Dr. Lucas Raspall.",
            },
            {
              time: "12:30 hrs.",
              title: "Cómo el apego impacta en la construcción de la mente.",
              person: "Dra. Inés Di Bartolo.",
            },
            {
              time: "14:00 hrs.",
              title:
                "De la supervivencia a la experiencia de seguridad emocional:",
              subtitle:
                "Aportes de la teoría Polivagal a la comprensión del trauma.",
              person: "Dra Leila Jorquera.",
            },
          ]}
        />

        <CalendarDay
          title="30 de marzo"
          color="#7BC7B9"
          cards={[
            {
              time: "9:00 hrs.",
              title: "Trauma relacional y apego desorganizado. ",
              person: "Dra. Inés Di Bartolo.",
            },
            {
              time: "10:30 hrs.",
              title: "Transtorno límite de la personalidad:",
              subtitle: "Génesis y clínica.",
              person: "Dr. Lucas Raspall.",
            },
            {
              time: "12:30 hrs.",
              title:
                "De la evaluación formativa acompañamiento post adoptivo: ",
              subtitle: "La construcción de vínculos en la adopción.",
              person: "Dr. Esteban Gómez Muzzio.",
            },
            {
              time: "14:00 hrs.",
              title:
                "El camino hacia la conexión y la seguridad emocional desde la teoría polivagal y el apego.",
              person: "Dra Leila Jorquera.",
            },
          ]}
        />

        <CalendarDay
          title="31 de marzo"
          color="#EDC748"
          cards={[
            {
              time: "9:00 hrs.",
              title:
                "Comprender los síntomas infantiles de la desvinculación. ",
              person: "Dra. Inés Di Bartolo.",
            },
            {
              time: "10:30 hrs.",
              title:
                "Trauma parental, trauma infantil y resiliencia integral en la adopción.",
              person: "Dr. Esteban Gómez Muzzio. ",
            },
            {
              time: "12:30 hrs.",
              title: "¿Nuevos patrones vinculares?",
              subtitle:
                " La historia encarnada en el cuerpo, en el cerebro y entre nosotros.",
              person: "Dra Leila Jorquera.",
            },
            {
              time: "14:00 hrs.",
              title: "Crianza positiva: ",
              subtitle:
                "Estrategias para el acompañamiento de niños con historias de maltrato.",
              person: "Dr. Lucas Raspall.",
            },
          ]}
        />
      </ComponentContainer>
    </section>
  );
};

interface CalendarDayProps {
  title: string;
  color: "#EB6D4E" | "#7BC7B9" | "#EDC748";
  cards: CardProps[];
}

const CalendarDay = (props: CalendarDayProps) => {
  return (
    <div css={styles.calendarDay()}>
      <h3 css={styles.calendarDayTitle(props?.color)}>{props?.title}</h3>
      <div css={styles.calendarDayGrid()}>
        {props.cards.map((card, index) => {
          return <Card key={index} {...card} />;
        })}
      </div>
    </div>
  );
};

interface CardProps {
  time: string;
  title: string;
  person: string;
  subtitle?: string;
}

const Card = (props: CardProps) => {
  return (
    <div css={styles.card()}>
      <p css={styles.cardTime()}>{props?.time}</p>
      <p css={styles.cardTitle()}>{props?.title}</p>
      {props?.subtitle ? (
        <p css={styles.cardSubtitle()}>{props?.subtitle}</p>
      ) : (
        <></>
      )}
      <p css={styles.cardPerson()}>{props?.person}</p>
    </div>
  );
};
