/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Appbar = ({
  homeRef,
  panelRef,
  programRef,
  sponsorRef,
}: {
  homeRef: any;
  panelRef: any;
  programRef: any;
  sponsorRef: any;
}) => {
  //Declarar las constantes que harán el scroll

  const scrollToHome = () => homeRef.current.scrollIntoView();
  const scrollToPanel = () => panelRef.current.scrollIntoView();
  const scrollToProgram = () => programRef.current.scrollIntoView();
  const scrollToSponsor = () => sponsorRef.current.scrollIntoView();

  const links = [
    { text: "Inicio", onClick: scrollToHome },
    { text: "Ponentes", onClick: scrollToPanel },
    { text: "Programa", onClick: scrollToProgram },
    { text: "Sponsors", onClick: scrollToSponsor },
  ];

  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <ul css={styles.list}>
          {links?.map((link) => {
            return (
              <a key={link.text} onClick={link.onClick} css={styles.link}>
                {link.text}
              </a>
            );
          })}
        </ul>
        <div css={styles.socialContainer}>
          <a
            href="https://www.facebook.com/fundacionamardelsureste"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              css={styles.social}
              src="./congress-2023/social-1.svg"
              alt="Fundación amar read social"
            />
          </a>
          <a
            href="https://www.instagram.com/fundacionamardelsureste/?igshid=NDc0ODY0MjQ%3D"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              css={styles.social}
              src="./congress-2023/social-2.svg"
              alt="Fundación amar read social"
            />
          </a>
          <a
            href="https://wa.me/+529994383248"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              css={styles.social}
              src="./congress-2023/social-3.svg"
              alt="Fundación amar read social"
            />
          </a>
        </div>
      </ComponentContainer>
    </section>
  );
};
