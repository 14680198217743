import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../utils";

export const decorationContainer = css`
  overflow: hidden;

  ${getMediaMin("md")(`
    ${ui.flexCenter()}
  `)}
`;

export const decoration = css`
  width: 150%;
  transform: translateX(-17%);

  ${getMediaMin("md")(`
    width: 100%;
    max-width: ${ui.rem(1000)};
    transform: translateX(0%);
  `)}
`;
