/** @jsxImportSource @emotion/react */
import {
  AnimationLeaf,
  Column,
  ContainerHalf,
  Image,
  Paragraph,
} from "../../../../components";
import { useAnimationLeaf } from "../../../../utils";

import * as styles from "./styles";

export const HomeDoYouKnownSection = () => {
  const { leafs } = useAnimationLeaf({
    left: [60, 90],
    top: [30, 50],
  });

  return (
    <section css={styles.section}>
      {leafs.map((leaf) => {
        return (
          <AnimationLeaf
            key={leaf.id}
            delay={leaf.delay}
            theme="orange"
            version={leaf.version}
            css={styles.decorationLeafs({
              left: leaf.left,
              top: leaf.top,
              size: leaf.size,
              random: leaf.random,
            })}
          />
        );
      })}
      <div css={styles.container}>
        <Column css={styles.columnLeft}>
          <ContainerHalf space={"md"} direction="left">
            <Paragraph
              title="¿Sabías que?"
              text="En Yucatán se registraron 469.4 reportes de maltrato por cada 100 mil niños de la entidad. Se consideró maltrato infantil; al daño físico, al abuso sexual, abandono, maltrato emocional, omisión de cuidados, negligencia y explotación."
              direction="center"
              directionLg="left"
            />
          </ContainerHalf>
        </Column>

        <Column css={styles.columnRight}>
          <Image
            css={styles.imageMain}
            src="./files/home-5.svg"
            alt="Fundación amar niños en un árbol"
          />
        </Column>
      </div>
    </section>
  );
};
