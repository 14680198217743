import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const button = (type: "sm" | "md" | "lg") => {
  const getSize = () => {
    if (type === "sm") {
      return `
        font-size: ${ui.rem(18)};
        line-height: ${ui.rem(18)};
        padding: ${ui.rem(8)} ${ui.rem(16)};
        border-radius: ${ui.rem(200)};


      `;
    } else if (type === "lg") {
      return `
        border-radius: ${ui.rem(200)};
        font-size: ${ui.rem(32)};
        line-height: ${ui.rem(32)};
        padding: ${ui.rem(16)} ${ui.rem(30)};
      `;
    } else {
      return `
      border-radius: ${ui.rem(200)};
      font-size: ${ui.rem(24)};
      line-height: ${ui.rem(24)};
      padding: ${ui.rem(10)} ${ui.rem(20)};

    `;
    }
  };

  return css`
    ${getSize()}
    font-family: "Solway";
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.05em;
    color: #4e4e4e;
    ${ui.flexCenter()};
    cursor: pointer;
    transition: background 500ms, box-shadow 300ms;
    background: #f7db38;

    :hover {
      background: #ffe861;
      box-shadow: 2px 2px 8px #00000059;
    }

    :active {
      box-shadow: 0px 0px 2px #00000059;
    }
  `;
};
