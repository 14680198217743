import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
  padding-bottom: 100px;
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  ${ui.column("xl")}
  position: relative;
  padding-top: 0px;
  align-items: center;
`;

export const header = () => {
  return css`
    ${ui.column("xs")}
  `;
};

export const title = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: #7f7f7f;
  `;
};

export const subtitle = () => {
  return css`
    ${title()}
    color: #da7059;
  `;
};

export const grid = () => {
  return css`
    ${ui.row("xl")}
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  `;
};

export const img = () => {
  return css`
    max-height: 200px;
    width: auto;

    img {
      max-height: 200px;
      width: auto;
    }
  `;
};
