import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.column("xl")};
  ${ui.flexCenter()};
  position: relative;
  padding-top: 0px;
  align-items: center;
`;

// CalendarDay
export const calendarDay = () => {
  return css`
    ${ui.column("md")}
    width: 100%;
  `;
};

export const calendarDayTitle = (color: string) => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #ffffff;
    background: ${color};
    border-radius: 30px;
    padding: 10px 10px;
  `;
};

export const calendarDayGrid = () => {
  return css`
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(100%, ${450 / 16}rem), 1fr)
    );
    ${ui.gridRepeat("md")}
  `;
};

// Card
export const card = () => {
  return css`
    background: #ececec;
    border-radius: 60px;
    padding: 40px;
    ${ui.column(10)}
  `;
};

export const cardTime = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #5b5b5f;
  `;
};

export const cardTitle = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #5b5b5f;
  `;
};

export const cardSubtitle = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #43a492;
  `;
};

export const cardPerson = () => {
  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #5b5b5f;
  `;
};
