/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useState } from "react";
import { Scaffold } from "../../components";
import { ContactBannerSection, ContactFormSection } from "./sections";

import * as styles from "./styles";

export const ContactPage = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const restartForm = () => {
    setForm({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const _setForm = (props: any) => {
    setShowAlert(false);
    return setForm(props);
  };

  const sendInformation = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/contact`, form);

      setShowAlert(true);
      restartForm();
    } catch (error: any) {
      setShowAlert(true);
      restartForm();
    }
  };

  return (
    <Scaffold css={styles.page}>
      <ContactBannerSection />
      <ContactFormSection
        form={form}
        setForm={_setForm}
        sendInformation={sendInformation}
        showAlert={showAlert}
      />
    </Scaffold>
  );
};
