/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useState } from "react";
import { Scaffold } from "../../components";
import {
  VoluntarioInterestsSection,
  VoluntarioContactSection,
  VoluntarioDoYouWantToBeSection,
  VoluntarioVideoSection,
} from "./sections";

import * as styles from "./styles";

export const VoluntariesPage = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [form, setForm] = useState({
    name: "",
    lastname: "",
    address: "",
    state: "",
    phone: "",
    email: "",
    experience: "",
    "voluntary-cooking": false,
    "voluntary-teaching": false,
    "voluntary-nurse": false,
    "voluntary-warehouse": false,
    "voluntary-afanador": false,
    "voluntary-washer": false,
    "voluntary-sport-teacher": false,
    "voluntary-handcraft": false,
    "voluntary-stories": false,
  });

  const restartForm = () => {
    setForm({
      name: "",
      lastname: "",
      address: "",
      state: "",
      phone: "",
      email: "",
      experience: "",
      "voluntary-cooking": false,
      "voluntary-teaching": false,
      "voluntary-nurse": false,
      "voluntary-warehouse": false,
      "voluntary-afanador": false,
      "voluntary-washer": false,
      "voluntary-sport-teacher": false,
      "voluntary-handcraft": false,
      "voluntary-stories": false,
    });
  };

  const _setForm = (props: any) => {
    setShowAlert(false);
    return setForm(props);
  };

  // const sendInformation = () => {
  //   setShowAlert(true);
  //   restartForm();
  // };

  const sendInformation = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/voluntaries`, form);

      setShowAlert(true);
      restartForm();
    } catch (error: any) {
      setShowAlert(true);
      restartForm();
    }
  };

  return (
    <Scaffold css={styles.page}>
      <VoluntarioVideoSection />
      <VoluntarioDoYouWantToBeSection />
      <VoluntarioContactSection form={form} setForm={_setForm} />
      <VoluntarioInterestsSection
        form={form}
        setForm={_setForm}
        sendInformation={sendInformation}
        showAlert={showAlert}
      />
    </Scaffold>
  );
};
