import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../utils";

export const decoration = css`
  width: 100%;
  max-width: ${ui.rem(350)};

  ${getMediaMin("lg")(`
    max-width: ${ui.rem(400)};
  `)};
`;
