import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export const section = css`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const decorationSpiral = css`
  position: absolute;
  left: calc(${ui.rem("lg")} * -2);
  top: 0px;

  * {
    fill: #ff6b6b;
  }
`;

export const decorationLine = css`
  position: absolute;
  right: calc(${ui.rem("lg")} * -2);
  bottom: 0px;

  * {
    fill: #e6c157;
  }
`;

export const container = css`
  ${ui.column(0)};
  ${ui.flexCenter()};

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row("md")};
  }
`;

export const columnLeft = css`
  order: 1;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    order: 0;
    width: 50%;
    margin-right: -200px;
  }
`;

export const columnRight = css`
  order: 0;
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: 50%;
  }
`;

export const imageMain = css``;
