import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const decorationSquare = css`
  position: absolute;
  left: 0px;
  top: 0px;
`;

export const decorationW = css`
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: rotate(180deg);

  * {
    fill: #ae8dcc;
  }

  ${getMediaMax("md")(`
    display: none;
  `)}
`;

export const container = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
  ${ui.column("md")};

  ${getMediaMin("md")(`
    ${ui.row("sm")};
  `)}

  ${getMediaMin("lg")(`
    ${ui.row("xl")};
  `)}
`;

export const imgKids = css`
  width: 100%;
  max-width: ${ui.rem(350)};
  z-index: 1;

  ${getMediaMin("sm")(`
    max-width: ${ui.rem(400)};
  `)}

  ${getMediaMin("md")(`
    max-width: ${ui.rem(300)};
  `)}

  ${getMediaMin("lg")(`
    max-width: ${ui.rem(500)};
  `)}
`;

export const form = css`
  width: 100%;
  ${ui.column("sm")}
  max-width: ${ui.rem(500)};
`;

export const containerText = css`
  height: 100%;
  ${ui.flexCenter()};
  position: relative;
`;

export const button = css`
  margin: auto;
  margin-top: ${ui.rem("md")};

  ${getMediaMin("md")(`
    margin-right: ${ui.rem(60)};
  `)}
`;

export const textAlert = (showAlert: boolean) => css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #797268;
  max-width: ${ui.rem(600)};
  animation: alertAnimation 1s linear;

  ${showAlert
    ? `
    `
    : `
      display: none;
    `}

  @keyframes alertAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
