import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMax, getMediaMin } from "../../utils";

export type OpenTypes = "opening" | "open" | "closing" | "close" | "close-full";
export type Theme = "dark" | "transparent";

export const header = (appbarDown: boolean, theme: Theme) => {
  let backgroundColorUp = "transparent";

  if (theme === "dark") {
    backgroundColorUp = "#f7db38";
  } else {
    backgroundColorUp = "transparent";
  }

  return css`
    position: fixed;
    width: 100%;
    z-index: 3;
    transition: background-color 600ms;

    background-color: ${appbarDown ? "#fdfcf9" : backgroundColorUp};

    ${appbarDown
      ? `
        border-bottom: 1px solid #5E5A5469;
      `
      : ``}
  `;
};

export const container = css`
  ${ui.row("md")};
  align-items: center;
  justify-content: space-between;
  padding-top: ${ui.rem("sm")};
  padding-bottom: ${ui.rem("sm")};

  ${getMediaMin("lg")(`
    padding-top: ${ui.rem("md")};
    padding-bottom: ${ui.rem("md")};
  `)}
`;

// Link
export const link = (active: boolean, mobile: boolean) => css`
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.05em;

  cursor: pointer;
  background-color: transparent;
  color: #5e5a54;
`;

// NavDesktop
export const navDesktop = css`
  ${ui.row("xl")};
  ${ui.flexCenter()}
  margin: auto;

  ${getMediaMax("lg")(`
    display: none;
  `)}
`;

export const buttonLeft = css`
  ${getMediaMax("lg")(`
      margin-right: auto;
      margin-top: ${ui.rem("xl")};
      background-color: #ffffff;
      ${ui.column(5)};
  `)}

  ${getMediaMin("lg")(`
    position: absolute;
    left: ${ui.rem("lg")};
    background-color: #f57158;
    font-weight: 300;
    color: #ffffff;
    ${ui.column(5)};

    strong {
      font-weight: 600;
    }

    :hover {
      background-color: #D45F48;
    }
  `)}
`;

export const buttonDonation = css`
  ${getMediaMax("lg")(`
      margin-right: auto;
      // margin-top: ${ui.rem("xl")};
      background-color: #ffffff;
  `)}

  ${getMediaMin("lg")(`
    position: absolute;
    right: ${ui.rem("lg")};
  `)}
`;

export const imageContainer = css`
  height: 100%;
  cursor: pointer;
`;

export const brand = (appbarDown: boolean) => css`
  transition: height 600ms;
  height: ${appbarDown ? `${ui.rem(50)}` : `${ui.rem(100)}`};
`;

// Navmobile
export const layer = (open: OpenTypes) => {
  let opacity: number = 0;

  if (open === "opening") {
    opacity = 0;
  } else if (open === "open") {
    opacity = 1;
  } else if (open === "closing") {
    opacity = 1;
  } else if (open === "close") {
    opacity = 0;
  } else if (open === "close-full") {
    opacity = 0;
  }

  return css`
    ${ui.column("md")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000075;
    transition: opacity 600ms;

    @media (min-width: ${ui.breakpoints.lg}px) {
      display: none;
    }

    opacity: ${opacity};
  `;
};

export const appbarMobile = css`
  ${ui.row("md")};
  ${ui.flexCenter()};
  justify-content: space-between;
  width: 100%;

  ${getMediaMin("lg")(`
    display: none;
  `)}
`;

export const appbarMobileLogo = css`
  width: ${ui.rem(50)};

  * {
    fill: #e4ce55 !important;
  }
`;

export const menu = css`
  color: #e4ce55;
  cursor: pointer;

  @media (min-width: ${ui.breakpoints.lg}px) {
    display: none;
  }
`;

export const nav = (open: OpenTypes) => css`
  @media (max-width: ${ui.breakpoints.lg - 1}px) {
    ${ui.column("md")};
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    max-width: ${ui.rem(300)};
    height: 100vh;
    background-color: #f7db38;
    padding: ${ui.rem("md")};
    ${open === "close-full" ? "display: none;" : ""}
  }

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row("md")};

    &.animate__animated {
      animation: none;
    }
  }

  ${getMediaMin("lg")(`
    display: none;
  `)}
`;

export const sidebarMobileLogo = css`
  width: ${ui.rem(100)};

  * {
    fill: #5e5a54 !important;
  }
`;

export const sidebarBody = css`
  ${ui.column("md")};
  height: 100%;
`;
