/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const DonationBannerSection = () => {
  return (
    <section css={styles.section}>
      <Image
        css={styles.img}
        src="./files/donation-1.png"
        srcXs="./files/donation-1-sm.png"
        srcSm="./files/donation-1-sm.png"
        srcMd="./files/donation-1-sm.png"
        alt="Fundación amar niños en un árbol"
      />
      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          text="El apoyo de nuestros principales donadores garantiza que nuestros beneficiarios tengan un espacio seguro donde reciben la mejor atención y cuidado."
          direction="center"
          directionLg="center"
        />
      </ComponentContainer>
    </section>
  );
};
