import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

export type ImageExpandType = "width" | "height" | "both";

export const imgContainer = (width: string | number = "100%") => {
  return css`
    max-width: ${typeof width === "string" ? width : ui.rem(width)};
    ${ui.column(0)};
  `;
};

export const img = css`
  width: 100%;
  // height: 100%;
`;
