/** @jsxImportSource @emotion/react */

import * as styles from "./styles";

export interface InputProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: any;
  name: string;
  textArea?: boolean;
}

export const Input = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  textArea,
}: InputProps) => {
  if (textArea) {
    return (
      <div css={styles.inputContainer}>
        <label css={styles.inputLabel}>{label}</label>
        <textarea
          name={name}
          css={[styles.input, styles.inputTextArea]}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  return (
    <div css={styles.inputContainer}>
      <label css={styles.inputLabel}>{label}</label>
      <input
        name={name}
        css={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
