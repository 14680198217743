/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { AnimationTalking, Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const HomePsychologySection = () => {
  return (
    <section css={styles.section}>
      <Image
        src="./files/lion.png"
        alt="Decoración"
        css={styles.decorationLion}
      />

      <Image src="./files/home-10.png" alt="Decoración" />

      <div css={styles.beforeContainer}>
        <ComponentContainer css={styles.container}>
          <Paragraph
            css={styles.text}
            title="Salud psicológica"
            text="Es importante satisfacer la necesidad de afecto de los niños y fomentar su recuperación emocional, pues el poder establecer lazos positivos es lo que les permitirá reintegrarse a una familia y a la sociedad."
            direction="center"
            directionLg="center"
          />
        </ComponentContainer>
      </div>

      <Image src="./files/home-11.png" alt="Decoración" />
      <AnimationTalking />
    </section>
  );
};
