/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Contact = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <div css={styles.card()}>
          <h3 css={styles.title()}>INFORMES:</h3>
          <a
            href="https://wa.me/+529994383248"
            target="_blank"
            rel="noreferrer"
            css={styles.link()}
          >
            <Image
              css={styles.icon}
              src="./congress-2023/contact-3.svg"
              alt="Fundación amar contacto"
            />
            999.438.32.48
          </a>
          <a
            href="mailto:anaek@micemeetings.mx"
            target="_blank"
            rel="noreferrer"
            css={styles.link()}
          >
            <Image
              css={styles.icon}
              src="./congress-2023/contact-4.svg"
              alt="Fundación amar contacto"
            />
            anaek@micemeetings.mx
          </a>
        </div>
        <div css={styles.grid()}>
          <p css={styles.gridTitle()}>Sede:</p>
          <div css={styles.grid2()}>
            <Image
              css={styles.img}
              src="./congress-2023/contact-1.png"
              alt="Fundación amar patrocinador"
            />
            <Image
              css={styles.img}
              src="./congress-2023/contact-2.png"
              alt="Fundación amar patrocinador"
            />
          </div>
        </div>
      </ComponentContainer>
    </section>
  );
};
