/** @jsxImportSource @emotion/react */

import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../Image";
import * as styles from "./styles";

export const Footer = () => {
  return (
    <footer css={styles.footer}>
      <Image src="./files/footer-1.png" alt="decoración inferior página" />
      <div css={styles.beforeContainer}>
        <ComponentContainer css={styles.container}>
          <div css={styles.containersContent}>
            <div className="links-social" css={styles.containerSon}>
              <a
                css={styles.containerColumns}
                href="https://www.facebook.com/fundacionamardelsureste"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                css={styles.containerColumns}
                href="https://instagram.com/fundacionamardelsureste?igshid=NDc0ODY0MjQ="
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div css={styles.containerSon}>
              <a
                css={styles.containerColumns}
                href="mailto:informes@fundacionamar.org"
              >
                <i className="far fa-envelope"></i>
                informes@fundacionamar.org
              </a>
            </div>
            <div css={styles.containerSon}>
              <a css={styles.linkTwoRows} href="tel:+529999697394">
                <span>
                  Información, Inversión social, alianzas y donaciones.
                </span>
                <span>
                  <i className="fas fa-phone-alt"></i>
                  +52 9999-69-73-94
                </span>
              </a>
            </div>
            <div css={styles.containerSon}>
              <a css={styles.linkTwoRows} href="tel:+529999478434">
                <span>Área jurídica-contable</span>
                <span>
                  <i className="fas fa-phone-alt"></i>
                  +52 9999-47-84-34
                </span>
              </a>
            </div>
          </div>
          <div css={styles.containersContent}>
            <div css={styles.containerSon}>Todos los derechos reservados.</div>
            <a
              href="./files/AVISO-DE-PRIVACIDAD-INTEGRAL-INVERSIONISTAS-SOCIALES-Y-VOLUNTARIOS FUNDACION AMAR.pdf"
              css={styles.containerSon}
              download
            >
              Aviso de Privacidad
            </a>
          </div>
        </ComponentContainer>
      </div>
    </footer>
  );
};
