/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const VoluntarioVideoSection = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          text="Estos son todos los nidos que hemos creado. 
          <strong>Muchas gracias a  todos nuestros voluntarios.</strong>"
          direction="center"
          directionLg="center"
        />
        <div css={styles.videoContainer}>
          <video
            css={styles.video}
            muted
            playsInline
            controls
            src="./files/presentacion.mp4"
          ></video>

          <Image
            src="./files/voluntaries-2.png"
            alt="Decoración"
            css={styles.imgBaby}
          />
        </div>
      </ComponentContainer>
    </section>
  );
};
