/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image, Paragraph } from "../../../../components";

import * as styles from "./styles";

export const VoluntarioDoYouWantToBeSection = () => {
  return (
    <section css={styles.section}>
      <div css={styles.beforeContainer}>
        <ComponentContainer css={styles.container}>
          <Paragraph
            css={styles.text}
            title="¿Quieres ser voluntario?"
            text="#JuntosCambiamosSuHistoria"
            direction="center"
            directionLg="center"
            theme="light"
          />
        </ComponentContainer>
      </div>

      <Image
        css={styles.imgPeople}
        src="./files/voluntaries-4.svg"
        alt="Decoración"
      />
    </section>
  );
};
