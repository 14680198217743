/** @jsxImportSource @emotion/react */
import {
  Column,
  ContainerHalf,
  DecorationEqual,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const HomeIntegrationSection = () => {
  return (
    <div>
      <section css={styles.section}>
        <DecorationEqual css={styles.decorationEqual} />
        <div css={styles.container}>
          <Column css={styles.columnLeft}>
            <ContainerHalf space={"md"} direction="left">
              <Paragraph
                title="Integración a 
                la familia"
                text="Nuestra meta es procurar que nuestros beneficiarios permanezcan en la institución solo el tiempo necesario para sanar sus heridas físicas y emocionales y que tengan un desarrollo adecuado para poder integrarse posteriormente a una familia funcional (biológica o adoptiva) con padres que les podrán brindar la seguridad y el cariño que necesitan."
                direction="center"
                directionLg="left"
              />
            </ContainerHalf>
          </Column>

          <Column css={styles.columnRight}>
            <Image
              css={styles.imageMain}
              src="./files/home-14.png"
              alt="Fundación amar integración familiar"
            />
          </Column>
        </div>
      </section>
    </div>
  );
};
