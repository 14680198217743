import { useEffect, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface LeafI {
  id: string;
  delay: number;
  created: Date;
  left: number;
  top: number;
  size: number;
  version: number;
  random: number;
}

export interface UseAnimationLeafProps {
  left?: number[];
  top?: number[];
  delay?: number;
  items?: number;
  itemsStart?: number;
  secondsChange?: number;
}

export const useAnimationLeaf = ({
  left = [0, 100],
  top = [10, 20],
  delay = 3000,
  items = 1,
  itemsStart = 5,
  secondsChange = 16,
}: UseAnimationLeafProps) => {
  const [leafs, setLeafs] = useState<LeafI[]>([]);

  const addLeaf = () => {
    const _random = Math.random();
    const _delay = Math.random() * delay;
    const _left = Math.floor(Math.random() * (left[1] - left[0] + 1) + left[0]);
    const size = Math.floor(Math.random() * (120 - 90 + 1) + 90);
    const _top = Math.floor(Math.random() * (top[1] - top[0] + 1) + top[0]);
    const version = Math.floor(Math.random() * (5 - 1 + 1) + 1);

    setLeafs((leafs) => {
      return [
        ...leafs.filter((leaf) => {
          if (
            dayjs(dayjs(leaf.created).add(secondsChange, "second")).isBefore(
              dayjs(new Date())
            )
          ) {
            return false;
          } else {
            return true;
          }
        }),
        {
          id: `${Math.random() * 20000}`,
          delay: _delay,
          left: _left >= 90 ? 90 : _left,
          created: new Date(),
          top: _top,
          version,
          size,
          random: _random,
        },
      ];
    });
  };

  useEffect(() => {
    const newLeafs = Math.ceil(Math.random() * itemsStart);
    new Array(newLeafs).fill(null).forEach((value) => {
      addLeaf();
    });

    setInterval(() => {
      const newLeafs = Math.ceil(Math.random() * items);
      new Array(newLeafs).fill(null).forEach((value) => {
        addLeaf();
      });
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLeafs]);

  return { leafs };
};
