import { ui } from "@joinlean/utils-react";

export const getMediaMin = (
  size: "xs" | "sm" | "md" | "lg" | "xl" | number
) => {
  return (value: string) => {
    return `
    @media (min-width: ${
      typeof size === "number" ? size : ui.breakpoints[size]
    }px) {
      ${value}
    }
  `;
  };
};

export const getMediaMax = (
  size: "xs" | "sm" | "md" | "lg" | "xl" | number
) => {
  return (value: string) => {
    return `
    @media (max-width: ${
      (typeof size === "number" ? size : ui.breakpoints[size]) - 1
    }px) {
      ${value}
    }
  `;
  };
};
