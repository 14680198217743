/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import {
  DecorationEqual,
  DecorationLine,
  Image,
  Input,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const VoluntarioContactSection = ({ form, setForm }: any) => {
  const inputs = [
    {
      label: "Nombre",
      placeholder: "Escribir nombre",
      name: "name",
      value: form.name,
    },
    {
      label: "Apellido",
      placeholder: "Escribir apellido",
      name: "lastname",
      value: form.lastname,
    },
    {
      label: "Dirección",
      placeholder: "Escribir dirección",
      name: "address",
      value: form.address,
    },
    {
      label: "Estado",
      placeholder: "Escribir estado",
      name: "state",
      value: form.state,
    },
    {
      label: "Teléfono",
      placeholder: "Escribir teléfono",
      name: "phone",
      value: form.phone,
    },
    {
      label: "Correo",
      placeholder: "Escribir correo",
      name: "email",
      value: form.email,
    },
    {
      label: "¿Experiencia con niños?",
      placeholder: "Escribir experiencia",
      name: "experience",
      value: form.experience,
      textArea: true,
    },
  ];

  const onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((form: any) => {
      return {
        ...form,
        [name]: value,
      };
    });
  };

  return (
    <section css={styles.section}>
      <DecorationEqual css={styles.decorationEqual} />
      <DecorationLine css={styles.decorationLine} />

      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          text="<strong>Puedes apoyar ofreciendo servicio como voluntario en los programas y actividades de Fundación AMAR.
            </strong>"
          direction="center"
          directionLg="center"
        />
        <Paragraph
          css={styles.text}
          text="Mándanos tus datos y nosotros te contactaremos."
          direction="center"
          directionLg="center"
        />

        <div css={styles.formContainer}>
          <Image
            css={styles.imgKid1}
            src="./files/voluntaries-5.png"
            alt="Niño feliz por el voluntario"
          />
          <Image
            css={styles.imgKid2}
            src="./files/voluntaries-6.png"
            alt="Niño feliz por el voluntario"
          />
          <Image
            css={styles.imgLion}
            src="./files/lion.png"
            alt="Juguete de león de los niños"
          />

          {inputs.map((input, index) => {
            return <Input key={index} onChange={onChange} {...input} />;
          })}
        </div>
      </ComponentContainer>
    </section>
  );
};
