/** @jsxImportSource @emotion/react */
import { Scaffold } from "../../components";
import { FAQBannerSection, DonationKidsSection } from "./sections";

import * as styles from "./styles";

export const FAQPage = () => {
  return (
    <Scaffold css={styles.page}>
      <FAQBannerSection />
      <DonationKidsSection />
    </Scaffold>
  );
};
