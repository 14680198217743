/** @jsxImportSource @emotion/react */
import { Appbar } from "../Appbar";
import { Footer } from "../Footer";

import * as styles from "./styles";

export interface ScaffoldProps {
  children: any;
  className?: string;
  css?: any;
}

export const Scaffold = ({ children, className }: ScaffoldProps) => {
  return (
    <>
      <Appbar />
      <main css={styles.main} className={className}>
        {children}
      </main>
      <Footer />
    </>
  );
};
