/** @jsxImportSource @emotion/react */

import { ui } from "@joinlean/utils-react";
import * as styles from "./styles";

export interface ImageProps {
  src: string;
  srcXs?: string;
  srcSm?: string;
  srcMd?: string;
  srcLg?: string;
  alt: string;
  width?: string | number;
  className?: string;
  css?: any;
}

export const Image = ({
  className = "",
  width,
  src,
  srcXs,
  srcSm,
  srcMd,
  srcLg,
  alt,
}: ImageProps) => {
  return (
    <>
      <picture className={className} css={styles.imgContainer(width)}>
        {srcXs && (
          <source
            css={styles.img}
            media={`(max-width: ${ui.breakpoints.xs}px)`}
            srcSet={srcXs}
          />
        )}

        {srcSm && (
          <source
            css={styles.img}
            media={`(max-width: ${ui.breakpoints.sm}px)`}
            srcSet={srcSm}
          />
        )}

        {srcMd && (
          <source
            css={styles.img}
            media={`(max-width: ${ui.breakpoints.md}px)`}
            srcSet={srcMd}
          />
        )}

        {srcLg && (
          <source
            css={styles.img}
            media={`(max-width: ${ui.breakpoints.lg}px)`}
            srcSet={srcLg}
          />
        )}

        <img css={styles.img} src={src} alt={alt} />
      </picture>
    </>
  );
};
