import { Navigate, Route, Routes as RoutesDom } from "react-router-dom";
import {
  Congress2023,
  ContactPage,
  DonationPage,
  FAQPage,
  HomePage,
  VoluntariesPage,
} from "../pages";

export const Routes = () => {
  return (
    <>
      <RoutesDom>
        <Route path="/" element={<HomePage />} />
        <Route path="/voluntarios" element={<VoluntariesPage />} />
        <Route path="/preguntas" element={<FAQPage />} />
        <Route path="/contacto" element={<ContactPage />} />
        <Route path="/donaciones" element={<DonationPage />} />
        <Route path="/congreso_Internacional_2023" element={<Congress2023 />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </RoutesDom>
    </>
  );
};
