/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";

import {
  Button,
  DecorationDots,
  DecorationSpiral,
  Image,
  Paragraph,
} from "../../../../components";

import * as styles from "./styles";

export const VoluntarioInterestsSection = ({
  sendInformation,
  form,
  setForm,
  showAlert,
}: any) => {
  const inputs = [
    {
      label: "Cocinero",
      name: "voluntary-cooking",
      value: form["voluntary-cooking"],
    },
    {
      label: "Maestro",
      name: "voluntary-teaching",
      value: form["voluntary-teaching"],
    },
    {
      label: "Enfermero",
      name: "voluntary-nurse",
      value: form["voluntary-nurse"],
    },
    {
      label: "Almacenista",
      name: "voluntary-warehouse",
      value: form["voluntary-warehouse"],
    },
    {
      label: "Afanador",
      name: "voluntary-afanador",
      value: form["voluntary-afanador"],
    },
    {
      label: "Lavandero",
      name: "voluntary-washer",
      value: form["voluntary-washer"],
    },
    {
      label: "Maestro de deportes",
      name: "voluntary-sport-teacher",
      value: form["voluntary-sport-teacher"],
    },
    {
      label: "Taller de manualidades",
      name: "voluntary-handcraft",
      value: form["voluntary-handcraft"],
    },
    {
      label: "Cuenta cuentos",
      name: "voluntary-stories",
      value: form["voluntary-stories"],
    },
  ];

  return (
    <section css={styles.section}>
      <DecorationSpiral css={styles.decorationSpiral} />
      <DecorationDots css={styles.decorationDots} />

      <ComponentContainer css={styles.container}>
        <Paragraph
          css={styles.text}
          text="Me interesa ser voluntario en los siguientes
          tipos de actividades:"
          direction="center"
          directionLg="center"
        />

        <div css={styles.formContainer}>
          <Image
            css={styles.imgKid2}
            src="./files/voluntaries-7.png"
            alt="Niño feliz por el voluntario"
          />

          {inputs.map((input, index) => {
            return <Input key={index} setForm={setForm} {...input} />;
          })}
        </div>

        <p css={styles.textAlert(showAlert)}>
          Muchas gracias por querer ser parte de nuestra historia, estaremos en
          contacto contigo muy pronto.
        </p>

        <Button onClick={sendInformation}>Enviar</Button>
      </ComponentContainer>
    </section>
  );
};

interface InputProps {
  label: string;
  value: any;
  name: string;
  setForm: any;
}

const Input = ({ label, name, value, setForm }: InputProps) => {
  const onClick = () => {
    setForm((form: any) => {
      return {
        ...form,
        [name]: !form[name],
      };
    });
  };

  return (
    <div css={styles.inputContainer}>
      <label onClick={onClick} css={styles.inputLabel(value)}>
        {label}
      </label>
    </div>
  );
};
