import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../utils";

export const main = css`
  margin-top: ${ui.rem(100)};

  ${getMediaMin("lg")(`
    margin-top: ${ui.rem(250)};
  `)}
`;
