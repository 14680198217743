import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";

// BANNER
export const section = css`
  max-height: ${ui.rem(900)};
  ${ui.column("md")};
  ${ui.flexCenter()};
`;

export const img = css`
  width: 100%;
  max-width: ${ui.rem(1200)};
`;
