import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

// BANNER
export const section = css`
  max-height: ${ui.rem(900)};
  margin-top: ${ui.rem("md")};
  ${ui.column("md")};
  ${ui.flexCenter()};
  overflow: hidden;
`;

export const decorationXs = css`
  position: absolute;
  left: 0px;
  top: ${ui.rem(100)};
  width: ${ui.rem(100)};

  ${getMediaMin("lg")(`
    top: ${ui.rem(10)};
  `)}
`;

export const container = css`
  height: 100%;
  ${ui.column("md")}
  ${ui.flexCenter()};
  position: relative;
  max-width: ${ui.rem(800)};

  ${getMediaMin("lg")(`
    ${ui.row("xs")}
  `)}
`;

export const imgBg = css`
  position: absolute;
  width: 100%;
  z-index: -1;
  width: ${ui.rem(800)};
  max-width: ${ui.rem(1000)};

  ${getMediaMin("lg")(`
    width: ${ui.rem(1000)};
    max-width: ${ui.rem(1000)};
  `)}
`;

export const img = css`
  width: 100%;
  max-width: ${ui.rem(350)};
`;

export const text = css`
  max-width: ${ui.rem(400)};

  p {
    font-size: ${ui.rem(20)};
    line-height: ${ui.rem(30)};
  }
`;
