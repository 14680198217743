import { css } from "@emotion/react";
import { ui } from "@joinlean/utils-react";
import { getMediaMin } from "../../../../utils";

export const section = css`
  ${ui.column("md")};
  ${ui.flexCenter()};
  position: relative;
`;

export const container = css`
  height: 100%;
  ${ui.column(80)};
  ${ui.flexCenter()};
  position: relative;
  padding-top: 0px;
  align-items: center;
`;

export const imgBg = css``;

// CARD
export const card = () => {
  return css`
    ${ui.column("md")};

    ${getMediaMin("md")(`
      ${ui.row("md")};
    `)}
  `;
};

export const cardImage = (position: string) => {
  const getPositionStyle = () => {
    if (position === "left") {
      return `
   
      `;
    } else {
      return `
      ${getMediaMin("md")(`
        order: 1;
      `)}
      `;
    }
  };

  return css`
    max-width: 300px;
    ${getPositionStyle()}

    ${getMediaMin("sm")(`
      min-width: 300px;
    `)}
  `;
};

export const cardContent = () => {
  return css`
    ${ui.column("sm")};
    justify-content: center;
  `;
};

export const cardHeader = () => {
  return css`
    ${ui.column("md")};
    justify-content: space-between;
    align-items: flex-end;

    ${getMediaMin("sm")(`
      ${ui.row("md")};
    `)}
  `;
};

export const cardTitle = (color: string) => {
  return css`
    ${ui.column(10)};
    width: 100%;

    &,
    * {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      color: ${color};
    }

    ${getMediaMin("md")(`
      &,
      * {
        font-size: 50px;
      }
    `)}
  `;
};

export const cardSubtitle = () => {
  return css`
    & {
      font-weight: 700 !important;
    }
  `;
};

export const cardSubtitle2 = (position: "left" | "right") => {
  const getPositionStyle = () => {
    return `
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      

      ${getMediaMin("sm")(`
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
      `)}
    `;
  };

  return css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #353535;
    display: flex;
    ${getPositionStyle()}

    ${getMediaMin("sm")(`
      width: 100%;
      max-width: ${ui.rem(350)};
    `)}
  `;
};

export const cardText = () => {
  return css`
    &,
    * {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
    }

    & strong {
      font-weight: 600;
    }
  `;
};
