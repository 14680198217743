/** @jsxImportSource @emotion/react */
import { ComponentContainer } from "@joinlean/utils-react";
import { Image } from "../../../../components";

import * as styles from "./styles";

export const Panel = () => {
  return (
    <section css={styles.section}>
      <ComponentContainer css={styles.container}>
        <Card
          color="#94C6B8"
          position="left"
          img="./congress-2023/panel-1.png"
          name="Dra. Inés"
          lastName="Di Bartolo"
          title={`Doctora en Psicología Terapeuta y Supervisora Clínica.`}
          description={
            <>
              <strong>
                Doctora en Psicología, terapeuta y supervisora clínica.
              </strong>{" "}
              Es especialista apego, mentalización, resiliencia y reparación
              vincular. Dirige el Centro de Apego y Vínculos y es autora de los
              libros: "El apego: cómo nuestros vínculos nos hacen quienes
              somos"; y "Apego y Crianza"
            </>
          }
        />
        <Card
          color="#DA7059"
          position="right"
          img="./congress-2023/panel-2.png"
          name="Dr. Lucas"
          lastName="Raspall"
          title={`Médico Psiquiatra, Psicoterapeuta Cognitivo Posracionalista.`}
          description={
            <>
              <strong>Especialista en Psicoterapia Zen y Acupuntor.</strong> Se
              desempeña como profesor universitario titular de cátedras en las
              facultades de Medicina y Psicología. Ademas es{" "}
              <strong>vocero en Argentina</strong> de la Fundación América por
              la Infancia.
            </>
          }
        />

        <Card
          color="#F5CC42"
          position="left"
          img="./congress-2023/panel-3.png"
          name="Dr. Esteban"
          lastName="Gómez Muzzio"
          title={`Magíster en Psicología Clínica y Doctor en Psicología.`}
          description={
            <>
              Es{" "}
              <strong>
                psicólogo, psicoterapeuta, Magíster en Psicología Clínica, y
                Doctor en Psicología, graduado con distinción máxima.
              </strong>{" "}
              Cuenta con especialización en teoría del apego, psicología del
              desarrollo, terapia familiar, videofeedback, epistemología
              clínica, maltrato infantil y trauma complejo. Autor del libro
              "Competencias Parentales. Modelo ODISEA: un enfoque
              ecológico-relacional" (2022).
            </>
          }
        />

        <Card
          color="#DA7059"
          position="right"
          img="./congress-2023/panel-4.png"
          name="Mtra. Leila"
          lastName="Jorquera"
          title={`Psicóloga Clínica, Magíster en Salud Mental Infantil.`}
          description={
            <>
              <strong>Psicóloga clínica Infantil y de adultos.</strong> Asesora
              en Proyectos en Infancia e Intervenciones{" "}
              <strong>en Desarrollo socio-emocional y Educación Inicial</strong>{" "}
              tanto en Chile como en el extranjero. Posee{" "}
              <strong>Magíster en Salud Mental Infantil</strong> por la
              Universidad del Desarrollo, y realizó un{" "}
              <strong>Diplomado de Promoción de Apego Seguro.</strong>
            </>
          }
        />
      </ComponentContainer>
    </section>
  );
};

interface CardProps {
  name: string;
  position: "left" | "right";
  lastName: string;
  title: string;
  description: any;
  color: "#94C6B8" | "#DA7059" | "#F5CC42";
  img: string;
}

const Card = ({
  name,
  color,
  img,
  position,
  lastName,
  title,
  description,
}: CardProps) => {
  return (
    <div css={styles.card()}>
      <Image
        css={styles.cardImage(position)}
        src={img}
        alt="Fundación amar mapa"
      />
      <div css={styles.cardContent()}>
        <div css={styles.cardHeader()}>
          <h3 css={styles.cardTitle(color)}>
            {name}
            <span css={styles.cardSubtitle()}>{lastName}</span>
          </h3>
          <p css={styles.cardSubtitle2(position)}>{title}</p>
        </div>
        <p css={styles.cardText()}>{description}</p>
      </div>
    </div>
  );
};
